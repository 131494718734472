import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment-timezone';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import s from './DeviceSchematic.module.scss';
import { FormattedMessage } from 'react-intl';
import DeviceSchematicSvg from './DeviceSchematicSvg';
import DeviceSchematicSvg1 from './DeviceSchematicSvg1';
import Site from './Site';
import DeviceSchematicAlpha2Svg from './DeviceSchematicAlpha2Svg';
import DeviceSchematicAlpha2SvgVer3 from './DeviceSchematicAlpha2SvgVer3';
import PLCNextBasicSvg from './PLCNextBasicSvg';
import PLCNextDemoSvg from './PLCNextDemoSvg';
import Alpha2 from './alpha2.jpg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import diagramBlue from '../../assets/diagram-blue.svg';
import diagramGrey from '../../assets/diagram-grey.svg';
import tableBlue from '../../assets/table-blue.svg';
import tableGrey from '../../assets/table-grey.svg';
import siteBlue from '../../assets/site-blue.svg';
import siteGrey from '../../assets/site-grey.svg';
import SensorTable from '../SensorTable/SensorTable';
import { getSequenceName } from '../../utils';
import { IsWidgetAccessible, IsWidgetViewableInSwitchView } from '../../utils/widgetManager';
import RefreshData from './RefreshData';

const DeviceSchematic = ({
  sensorData,
  unitComponentParts,
  currentTimezone,
  userProfileWidgets,
  updateWidget,
  flowSequences,
  selectedUnit,
  setCurrentDeviceSequence
}) => {
  const [showSensors, setshowSensors] = useState(false);
  const [enableMouseZoom, setEnableMouseZoom] = useState(false);
  const handleClick = () => setshowSensors(!showSensors);
  const handleEnableMouseZoomClick = () => setEnableMouseZoom(!enableMouseZoom);
  const handleTableSwitchClick = () => {
    let updatedWidgets = userProfileWidgets.userProfileWidgets.map(obj => {
      if (obj.Code === 'REALTIMESENSORS') {
        obj.IsViewable = true;
      }
      if (obj.Code === 'DEVICESCHEMATICDIAGRAM') {
        obj.IsViewable = false;
      }
      if (obj.Code === 'UNITSITE') {
        obj.IsViewable = false;
      }
      return obj;
    });

    updateWidget(updatedWidgets);
  };

  const handleDiagramSwitchClick = () => {
    let updatedWidgets = userProfileWidgets.userProfileWidgets.map(obj => {
      if (obj.Code === 'REALTIMESENSORS') {
        obj.IsViewable = false;
      }
      if (obj.Code === 'DEVICESCHEMATICDIAGRAM') {
        obj.IsViewable = true;
      }
      if (obj.Code === 'UNITSITE') {
        obj.IsViewable = false;
      }
      return obj;
    });
    updateWidget(updatedWidgets);
  };

  const handleSiteSwitchClick = () => {
    let updatedWidgets = userProfileWidgets.userProfileWidgets.map(obj => {
      if (obj.Code === 'REALTIMESENSORS') {
        obj.IsViewable = false;
      }
      if (obj.Code === 'DEVICESCHEMATICDIAGRAM') {
        obj.IsViewable = false;
      }
      if (obj.Code === 'UNITSITE') {
        obj.IsViewable = true;
      }
      return obj;
    });

    updateWidget(updatedWidgets);
  };
  const siteDetails = selectedUnit?.siteDetails || null;
  const siteAssociations = selectedUnit?.siteAssociations || [];
  const gatewayAssociations = selectedUnit?.gatewayAssociations || [];

  let showDiagram = IsWidgetViewableInSwitchView(userProfileWidgets, 'DEVICESCHEMATICDIAGRAM');
  let showTable = IsWidgetViewableInSwitchView(userProfileWidgets, 'REALTIMESENSORS');
  let showSite = IsWidgetViewableInSwitchView(userProfileWidgets, 'UNITSITE') && siteDetails && siteDetails.SiteId;
  let isDiagramAccessible = IsWidgetAccessible(userProfileWidgets, 'DEVICESCHEMATICDIAGRAM');
  let isTableAccessible = IsWidgetAccessible(userProfileWidgets, 'REALTIMESENSORS');
  let isSiteAccessible = IsWidgetAccessible(userProfileWidgets, 'UNITSITE') && siteDetails && siteDetails.SiteId;

  const { timestamp, ...sensorReadings } = sensorData;
  const timestampMoment = moment(timestamp).tz(currentTimezone);
  const sensors = Object.keys(sensorReadings).sort();
  const labelChecked = <FormattedMessage id="deviceSchematic.diagramView" defaultMessage="Hide sensors" />;
  const labelUnchecked = <FormattedMessage id="deviceSchematic.sensorView" defaultMessage="Show sensors" />;
  const labelZoomChecked = <FormattedMessage id="deviceSchematic.mouseZoomHide" defaultMessage="Mouse Zoom Off" />;
  const labelZoomUnchecked = <FormattedMessage id="deviceSchematic.mouseZoomShow" defaultMessage="Mouse Zoom On" />;

  const diagramClass = showDiagram ? s.active : !showTable && !showSite && isDiagramAccessible ? s.active : s.nonActive;
  const tableClass = showTable
    ? s.active
    : diagramClass != s.active && !showDiagram && !showSite && isTableAccessible
    ? s.active
    : s.nonActive;
  const siteClass = showSite
    ? s.active
    : diagramClass != s.active && tableClass != s.active && !showDiagram && !showTable && isSiteAccessible
    ? s.active
    : s.nonActive;

  let currentSequence = (sensorData && sensorData.current_state) || null;

  // useEffect(() => {
  //   if (currentSequence)
  //   {
  //     const current_sequence = getSequenceName(flowSequences && flowSequences || [], currentSequence);
  //     setCurrentDeviceSequence('BLUE-PI-01',current_sequence)
  //   }
  // }, [currentSequence]);

  const sequence = getSequenceName((flowSequences && flowSequences) || [], currentSequence);

  const refreshDataWidget = isTableAccessible ? 'REALTIMESENSORS' : isSiteAccessible ? 'UNITSITE' : 'DEVICESCHEMATICDIAGRAM';

  var schematicDiagram = (
    <DeviceSchematicAlpha2Svg
      SensorData={sensorReadings}
      ShowSensors={showSensors}
      unitComponentParts={unitComponentParts}
      enableMouseZoom={enableMouseZoom}
    />
  );
  if (selectedUnit?.SchematicDiagramName === 'Alpha2DeviceSchematicSvg3') {
    schematicDiagram = (
      <DeviceSchematicAlpha2SvgVer3
        SensorData={sensorReadings}
        ShowSensors={showSensors}
        unitComponentParts={unitComponentParts}
        enableMouseZoom={enableMouseZoom}
        siteAssociations={siteAssociations}
        unitId={selectedUnit.UnitId}
      />
    );
  } else if (selectedUnit?.SchematicDiagramName === 'DeviceSchematicSvg1') {
    schematicDiagram = (
      <DeviceSchematicAlpha2Svg
        SensorData={sensorReadings}
        ShowSensors={showSensors}
        unitComponentParts={unitComponentParts}
        enableMouseZoom={enableMouseZoom}
      />
    );
  } else if (selectedUnit?.SchematicDiagramName === 'Alpha2Svg') {
    schematicDiagram = <img src={Alpha2} width="100%" />;
  } else if (selectedUnit?.SchematicDiagramName === 'PLCNextSvg') {
    schematicDiagram = (
      <PLCNextBasicSvg
        SensorData={sensorReadings}
        ShowSensors={showSensors}
        unitComponentParts={unitComponentParts}
        enableMouseZoom={enableMouseZoom}
      />
    );
  } else if (selectedUnit?.SchematicDiagramName === 'PLCNextDemoSvg') {
    schematicDiagram = (
      <PLCNextDemoSvg
        SensorData={sensorReadings}
        ShowSensors={showSensors}
        unitComponentParts={unitComponentParts}
        enableMouseZoom={enableMouseZoom}
      />
    );
  }

  return (
    <div className={s.sequenceWrapper}>
      <Row className={s.rowBorder}>
        <Col md={12} lg={6} className={s.sequenceTitle}>
          <div className={s.refreshInfo}>
            <h4>{(sequence && sequence.Name) || ''} </h4>
            {timestamp && <span className={s.timestamp}>Last updated {timestampMoment.format('DD-MMM-YYYY HH:mm:ss z')}</span>}
            <RefreshData widgetCode={refreshDataWidget} />
          </div>
        </Col>
        {isDiagramAccessible || isTableAccessible || isSiteAccessible ? (
          <Col md={12} lg={6} className={s.switchView}>
            <div className={`${s.switchViewRow} row mx-auto`}>
              {isSiteAccessible && (
                <div className="mr-3">
                  <img
                    src={siteClass == s.active ? siteBlue : siteGrey}
                    alt="Site view"
                    onClick={
                      !showSite
                        ? handleSiteSwitchClick
                        : () => {
                            return false;
                          }
                    }
                  ></img>
                  <span
                    className={siteClass}
                    onClick={
                      !showSite
                        ? handleSiteSwitchClick
                        : () => {
                            return false;
                          }
                    }
                  >
                    <FormattedMessage id="sequence.siteView" defaultMessage="Site View" />
                  </span>
                </div>
              )}
              {isSiteAccessible && (isDiagramAccessible || isTableAccessible) && <div className={s.pipeSeparator}></div>}
              {isDiagramAccessible && (
                <div className="mx-3">
                  <img
                    src={diagramClass == s.active ? diagramBlue : diagramGrey}
                    alt="Diagram view"
                    onClick={
                      !showDiagram
                        ? handleDiagramSwitchClick
                        : () => {
                            return false;
                          }
                    }
                  ></img>
                  <span
                    className={diagramClass}
                    onClick={
                      !showDiagram
                        ? handleDiagramSwitchClick
                        : () => {
                            return false;
                          }
                    }
                  >
                    <FormattedMessage id="sequence.diagram" defaultMessage="Diagram View" />
                  </span>
                </div>
              )}
              {isDiagramAccessible && isTableAccessible && <div className={s.pipeSeparator}></div>}
              {isTableAccessible && (
                <div className="ml-3">
                  <img
                    src={tableClass == s.active ? tableBlue : tableGrey}
                    alt="Table view"
                    onClick={
                      !showTable
                        ? handleTableSwitchClick
                        : () => {
                            return false;
                          }
                    }
                  ></img>
                  <span
                    className={tableClass}
                    onClick={
                      !showTable
                        ? handleTableSwitchClick
                        : () => {
                            return false;
                          }
                    }
                  >
                    <FormattedMessage id="sequence.table" defaultMessage="Table View" />
                  </span>
                </div>
              )}
            </div>
          </Col>
        ) : (
          <Col />
        )}
      </Row>
      {showDiagram || (!showTable && !showSite && isDiagramAccessible) ? (
        <div className={classNames(s.sequence)}>
          <div className="scaling-svg-container">{schematicDiagram}</div>

          {showSensors && 1 == 0 && (
            <div className={s.sensors}>
              {sensors.map((sensor, i) => {
                const sensorName = sensor.split('_')[0];
                const SensorInfo = unitComponentParts.filter(e => e.Name.toLowerCase() === sensorName.toLowerCase());
                if (!SensorInfo && SensorInfo.length === 0) return null;
                return (
                  <p key={`sequence-${sensorName}`} className={classNames(s[sensorName])}>
                    {sensorName}
                    <span> {`${Math.round(sensorData[sensor] * 100) / 100} ${SensorInfo[0].UoM}`}</span>
                    <span className={s.arrow}></span>
                  </p>
                );
              })}
            </div>
          )}
          <div className={s.switchContainer}>
            <ToggleSwitch
              handleClick={handleClick}
              classname={s.switch}
              checked={showSensors}
              labelChecked={labelChecked}
              labelUnchecked={labelUnchecked}
            />
            <ToggleSwitch
              handleClick={handleEnableMouseZoomClick}
              classname={s.switch}
              checked={enableMouseZoom}
              labelChecked={labelZoomChecked}
              labelUnchecked={labelZoomUnchecked}
            />
          </div>
        </div>
      ) : (
        <span />
      )}
      {showTable || (diagramClass != s.active && !showDiagram && !showSite && isTableAccessible) ? (
        <div>
          <SensorTable sensorData={sensorData} unitComponentParts={unitComponentParts} />
        </div>
      ) : (
        <span />
      )}
      {showSite || (diagramClass != s.active && tableClass != s.active && !showDiagram && !showTable && isSiteAccessible) ? (
        <div>
          <div className="scaling-svg-container">
            <Site siteDetails={siteDetails} gatewayAssociations={gatewayAssociations} siteAssociations={siteAssociations} />
          </div>
        </div>
      ) : (
        <span />
      )}
    </div>
  );
};

DeviceSchematic.defaultProps = {
  unitComponentParts: [],
  flowSequences: []
};

DeviceSchematic.propTypes = {
  sensorData: PropTypes.object.isRequired,
  unitComponentParts: PropTypes.array.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  updateWidget: PropTypes.func,
  flowSequences: PropTypes.array.isRequired
};

export default DeviceSchematic;
