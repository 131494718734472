import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ChartWrapper from '../ChartWrapper/ChartWrapperContainer';
import AddChartButton from '../AddChartButton/AddChartButton';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { useParams } from 'react-router-dom';

import s from './DataTrend.module.scss';

const DataTrend = ({ addChart, charts, selectedDevice, unitManager, userProfileWidgets }) => {
  const devicesObj = Object.values(selectedDevice);
  const { unitSerialNumber } = useParams();
  const modelSensors =
    (unitManager.selectedUnit.unitComponentPositions &&
      unitManager.selectedUnit.unitComponentPositions.filter(comp => comp.NeedFeedback && comp.IsParent === 0)) ||
    [];
  let redirect = false;

  const sensorOptions = modelSensors.sort().map(sensor => ({
    value: sensor.Name.toLowerCase(),
    label: sensor.Name.toLowerCase(),
    IsOCP: sensor.IsOCP,
    IsCCP: sensor.IsCCP,
    Name: sensor.Name
  }));

  return (
    <div className={s.dataTrend}>
      {devicesObj.length === 0 && <LoadingSpinner />}
      {redirect ? <Redirect to="/unit" /> : ''}
      <ContentTemplate
        selectedPage="dataTrend"
        userProfileWidgets={userProfileWidgets}
        widgetCode={'DATATRENDANALYSIS'}
        tagoutRequired={false}
      >
        <div className={s.chartOperation}>
          {IsWidgetAccessible(userProfileWidgets, 'DATATRENDANALYSIS_ADDCHART') && (
            <AddChartButton count={Object.values(charts).length} unitSerialNumber={unitSerialNumber} addChart={addChart} />
          )}
        </div>
        {Object.values(charts).map((chart, i) => (
          <ChartWrapper
            userProfileWidgets={userProfileWidgets}
            index={i}
            key={chart.id}
            unitSerialNumber={unitSerialNumber}
            {...chart}
            sensorOptions={sensorOptions}
            fromDataTrend={true}
          />
        ))}
      </ContentTemplate>
    </div>
  );
};

DataTrend.defaultProps = {
  selectedDevice: { unitSerialNumber: '' },
  unitManager: {
    selectedUnit: {
      unitComponentPositions: [],
      siteAssociations: []
    }
  }
};

DataTrend.propTypes = {
  addChart: PropTypes.func.isRequired,
  charts: PropTypes.object.isRequired,
  selectedDevice: PropTypes.object
};

export default DataTrend;
