import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './ModelComponentPosition.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialModelComponentPositionsState } from '../../reducers/initialState';
import ModelSubComponentPositionListTable from './ModelSubComponentPositionListTable';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isGraterThan, isRequired } from '../../utils';
import Cookies from 'js-cookie';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import uuid from 'uuid';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { compareValues } from '../../utils/';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import { IsWidgetAccessible } from '../../utils/widgetManager';
const ModelComponentPosition = ({
  userProfileWidgets,
  modelComponentPositionManager,
  saveModelComponentPosition,
  describeModelComponentPosition,
  changeModelComponentPosition,
  resetModelComponent,
  intl
}) => {
  let history = useHistory();
  let { modelComponentPositionId, parentModelComponentPositionId } = useParams();
  let isSubComponent = parentModelComponentPositionId != null ? true : false;
  let IsEdit = false;
  let widgetCode = '';
  const [localformErrors, setFormErrors] = useState({});
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const aggregationTypeList = [
    { value: null, label: intl.formatMessage({ id: 'modelComponentPositions.aggregationTypeNone', defaultMessage: 'None' }) },
    { value: 1, label: intl.formatMessage({ id: 'modelComponentPositions.aggregationTypeAvg', defaultMessage: 'Average' }) },
    { value: 2, label: intl.formatMessage({ id: 'modelComponentPositions.aggregationTypeMedian', defaultMessage: 'Median' }) },
    { value: 3, label: intl.formatMessage({ id: 'modelComponentPositions.aggregationTypeMax', defaultMessage: 'Max Value' }) },
    { value: 4, label: intl.formatMessage({ id: 'modelComponentPositions.aggregationTypeMin', defaultMessage: 'Min Value' }) },
    {
      value: 5,
      label: intl.formatMessage({ id: 'modelComponentPositions.aggregationTypeWeightedAvg', defaultMessage: 'Weighted Average' })
    }
  ];

  //Checking permission and redirecting to unauth page if unauthorised
  if (modelComponentPositionId === '0' && !IsWidgetAccessible(userProfileWidgets, 'MODELCOMPONENTPOSITION_ADDNEW')) {
    history.push('/unauth');
  }
  if (modelComponentPositionId !== '0' && !IsWidgetAccessible(userProfileWidgets, 'MODELCOMPONENTPOSITION_EDIT')) {
    history.push('/unauth');
  }

  if (modelComponentPositionId && modelComponentPositionId !== '0') {
    IsEdit = true;
    widgetCode = 'MODELCOMPONENTPOSITION_EDIT';
  } else {
    widgetCode = 'MODELCOMPONENTPOSITION_ADDNEW';
  }

  let backUrl = '/admin-controls/model-component?list=2';
  if (parentModelComponentPositionId) {
    backUrl = `/admin-controls/model-component/model-component-position/${parentModelComponentPositionId}`;
  }

  useEffect(() => {
    if (modelComponentPositionId) {
      if (parentModelComponentPositionId) {
        describeModelComponentPosition(modelComponentPositionId, widgetCode, parseInt(parentModelComponentPositionId));
      } else {
        describeModelComponentPosition(modelComponentPositionId, widgetCode);
      }
    }
  }, [describeModelComponentPosition, modelComponentPositionId]);

  useEffect(() => {
    if (
      !modelComponentPositionManager.selectedModelComponentPosition.NeedFeedback &&
      !modelComponentPositionManager.selectedModelComponentPosition.NeedState
    ) {
      setLocalModelComponentPosition({
        ...modelComponentPositionManager,
        selectedModelComponentPosition: {
          ...modelComponentPositionManager.selectedModelComponentPosition,
          PublishToCloud: false,
          IsDerived: false
        }
      });
    }
  }, [
    modelComponentPositionManager.selectedModelComponentPosition.NeedFeedback,
    modelComponentPositionManager.selectedModelComponentPosition.NeedState
  ]);

  //clean up
  useEffect(() => {
    return () => {
      resetModelComponent({
        ...initialModelComponentPositionsState
      });
    };
  }, []);

  // Update redux store
  const setLocalModelComponentPosition = currentState => {
    changeModelComponentPosition(currentState);
  };

  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();
    topFunction();

    if (!validateInput()) {
      return;
    }

    let saveData = modelComponentPositionManager.selectedModelComponentPosition;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.ModelComponentPositionId = 0;
      saveData.GuidIdentifier = uuidv4();
    }

    saveModelComponentPosition(saveData, widgetCode);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !modelComponentPositionManager ||
      !modelComponentPositionManager.selectedModelComponentPosition ||
      !modelComponentPositionManager.selectedModelComponentPosition.Name ||
      isRequired(modelComponentPositionManager.selectedModelComponentPosition.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'modelComponentPositions.nameRequired',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    if (
      !modelComponentPositionManager ||
      !modelComponentPositionManager.selectedModelComponentPosition ||
      !modelComponentPositionManager.selectedModelComponentPosition.Title ||
      isRequired(modelComponentPositionManager.selectedModelComponentPosition.Title, 1)
    ) {
      formErrors.Title = intl.formatMessage({
        id: 'modelComponentPositions.titleRequired',
        defaultMessage: 'Title is a mandatory field'
      });
      isValid = false;
    }

    if (
      modelComponentPositionManager &&
      modelComponentPositionManager.selectedModelComponentPosition &&
      modelComponentPositionManager.selectedModelComponentPosition.Title &&
      isGraterThan(modelComponentPositionManager.selectedModelComponentPosition.Title, 25)
    ) {
      formErrors.Title = intl.formatMessage({
        id: 'modelComponentPositions.maximumAllowedLength25',
        defaultMessage: 'Exceeded maximum length (25 characters)'
      });
      isValid = false;
    }

    if (
      !modelComponentPositionManager ||
      !modelComponentPositionManager.selectedModelComponentPosition ||
      !modelComponentPositionManager.selectedModelComponentPosition.ModelComponentTypeId
    ) {
      formErrors.ModelComponentTypeId = intl.formatMessage({
        id: 'modelComponentPositions.componentTypeRequired',
        defaultMessage: 'Component type is a mandatory field'
      });
      isValid = false;
    }

    if (
      parentModelComponentPositionId &&
      (!modelComponentPositionManager ||
        !modelComponentPositionManager.selectedModelComponentPosition ||
        !modelComponentPositionManager.selectedModelComponentPosition.ModelSubComponentTypeId)
    ) {
      formErrors.ModelSubComponentTypeId = intl.formatMessage({
        id: 'modelComponentPositions.componentSubTypeRequired',
        defaultMessage: 'Subcomponent type is a mandatory field'
      });
      isValid = false;
    }

    if (
      parentModelComponentPositionId &&
      (!modelComponentPositionManager ||
        !modelComponentPositionManager.selectedModelComponentPosition ||
        (modelComponentPositionManager.selectedModelComponentPosition.AggregationType &&
          !modelComponentPositionManager.selectedModelComponentPosition.AggregationDatapoint))
    ) {
      formErrors.AggregationDatapoint = intl.formatMessage({
        id: 'modelComponentPositions.aggregationDatapointRequired',
        defaultMessage: 'Aggregation datapoint  is a required when an aggregation type is selected'
      });
      isValid = false;
    }
    if (
      parentModelComponentPositionId &&
      (!modelComponentPositionManager ||
        !modelComponentPositionManager.selectedModelComponentPosition ||
        (modelComponentPositionManager.selectedModelComponentPosition.IsDerived &&
          !modelComponentPositionManager.selectedModelComponentPosition.Expression))
    ) {
      formErrors.Expression = intl.formatMessage({
        id: 'modelComponentPositions.expressionRequired',
        defaultMessage: 'Expression is required when the component is derived'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const componentOptions = () => {
    let componentOptions = [];

    modelComponentPositionManager.modelComponentTypes.forEach(element => {
      componentOptions.push({
        label: element.Name,
        value: element.ModelComponentTypeId
      });
    });
    return componentOptions.sort(compareValues('label'));
  };

  const subComponentOptions = () => {
    let subcomponentOptions = [];
    let selectedComponentTypes = modelComponentPositionManager.modelComponentTypes.find(
      item => item.ModelComponentTypeId == modelComponentPositionManager.selectedModelComponentPosition.ModelComponentTypeId
    );
    if (selectedComponentTypes) {
      selectedComponentTypes.modelSubComponents.forEach(element => {
        subcomponentOptions.push({
          label: element.ModelSubcomponentName,
          value: element.ModelSubComponentTypeId,
          isDisabled:
            modelComponentPositionManager.selectedModelComponentPosition.ExistingComponentSubPositions.find(
              item => item.ModelSubComponentTypeId == element.ModelSubComponentTypeId
            ) != null
        });
      });
    }
    return subcomponentOptions.sort(compareValues('label'));
  };

  const parentComponentOptions = () => {
    let componentOptions = [];

    modelComponentPositionManager.parentModelComponentPositions.forEach(element => {
      componentOptions.push({
        label: element.Name,
        value: element.ModelComponentPositionId
      });
    });
    return componentOptions.sort(compareValues('label'));
  };

  const onAddNewBtnClick = () => {
    let url = `/admin-controls/model-component/model-component-position/0/${modelComponentPositionId}`;
    history.push(url);
  };

  //on control value change
  const onChange = e => {
    let elementName = e.target.name;
    let elementValue = e.target.value;
    if (elementName == 'ComponentName') {
      elementName = 'Name';
      let namePrefix = `${modelComponentPositionManager.selectedModelComponentPosition.ParentModelComponentPositionName}_${modelComponentPositionManager.selectedModelComponentPosition.ModelSubComponentTypeName}`;
      if (
        modelComponentPositionManager.selectedModelComponentPosition.ParentModelComponentPositionId &&
        !elementValue.startsWith(namePrefix)
      ) {
        elementValue = namePrefix;
      }
    }
    setLocalModelComponentPosition({
      ...modelComponentPositionManager,
      selectedModelComponentPosition: { ...modelComponentPositionManager.selectedModelComponentPosition, [elementName]: elementValue }
    });
  };

  const onToggleChange = name => {
    var obj = { [name]: !modelComponentPositionManager.selectedModelComponentPosition[name] };
    if (name === 'HasRaw') {
      obj = {
        [name]: !modelComponentPositionManager.selectedModelComponentPosition[name],
        RawComponentName: modelComponentPositionManager.selectedModelComponentPosition.Name + 'RAW'
      };
    }
    if (name === 'NeedState') {
      if (!!modelComponentPositionManager.selectedModelComponentPosition[name]) {
        obj = {
          PeriodicSummaryCycle: false,
          ...obj
        };
      }
      obj = {
        NeedPeriodicSummaryMin: false,
        NeedPeriodicSummaryMax: false,
        PeriodicSummaryVolume: false,
        PeriodicSummaryRunHours: false,
        ...obj
      };
    }

    if (name === 'NeedFeedback') {
      if (!!modelComponentPositionManager.selectedModelComponentPosition[name]) {
        obj = {
          NeedPeriodicSummaryMin: false,
          NeedPeriodicSummaryMax: false,
          PeriodicSummaryVolume: false,
          PeriodicSummaryRunHours: false,
          ...obj
        };
      }

      obj = {
        PeriodicSummaryCycle: false,
        ...obj
      };
    }

    if (name === 'IsDerived') {
      if (!!modelComponentPositionManager.selectedModelComponentPosition[name]) {
        obj = {
          AggregationType: null,
          AggregationDatapoint: 10,
          WeightedStartPoint: 0,
          WeightedIncrement: 1,
          ...obj
        };
      }

      obj = {
        IsDerived: false,
        ...obj
      };
    }

    setLocalModelComponentPosition({
      ...modelComponentPositionManager,
      selectedModelComponentPosition: {
        ...modelComponentPositionManager.selectedModelComponentPosition,
        ...obj
      }
    });
  };

  const onDropdownComponentTypeChange = e => {
    setLocalModelComponentPosition({
      ...modelComponentPositionManager,
      selectedModelComponentPosition: { ...modelComponentPositionManager.selectedModelComponentPosition, ModelComponentTypeId: e.value }
    });
  };

  const onDropdownSubcomponentTypeChange = e => {
    let componentName = modelComponentPositionManager.selectedModelComponentPosition.Name;
    let componentNamePrefix = `${modelComponentPositionManager.selectedModelComponentPosition.ParentModelComponentPositionName}_${e.label}`;

    if (!componentName.startsWith(componentNamePrefix)) {
      componentName = componentNamePrefix;
    }

    setLocalModelComponentPosition({
      ...modelComponentPositionManager,
      selectedModelComponentPosition: {
        ...modelComponentPositionManager.selectedModelComponentPosition,
        ModelSubComponentTypeId: e.value,
        ModelSubComponentTypeName: e.label,
        Name: componentName
      }
    });
  };

  const onDropdownAggregationTypeChange = e => {
    if (!e.value) {
      setLocalModelComponentPosition({
        ...modelComponentPositionManager,
        selectedModelComponentPosition: {
          ...modelComponentPositionManager.selectedModelComponentPosition,
          AggregationType: null,
          AggregationDatapoint: 10,
          WeightedStartPoint: 0,
          WeightedIncrement: 1
        }
      });
    } else {
      setLocalModelComponentPosition({
        ...modelComponentPositionManager,
        selectedModelComponentPosition: {
          ...modelComponentPositionManager.selectedModelComponentPosition,
          AggregationType: e.value
        }
      });
    }
  };

  const onDropdownParentComponentPositionChange = e => {
    const modelComponentType = getModelComponentType(e.value);
    if (modelComponentType) {
      setLocalModelComponentPosition({
        ...modelComponentPositionManager,
        selectedModelComponentPosition: {
          ...modelComponentPositionManager.selectedModelComponentPosition,
          ParentModelComponentPositionId: e.value,
          ParentModelComponentPositionName: e.label,
          Name: `${e.label}_`,
          ModelComponentTypeId: modelComponentType.ModelComponentTypeId ?? null
        }
      });
    }
  };

  const getModelComponentType = modelComponentPositionId => {
    let modelComponentPosition = modelComponentPositionManager.parentModelComponentPositions.find(
      item => item.ModelComponentPositionId == modelComponentPositionId
    );
    if (modelComponentPosition) {
      return modelComponentPosition;
    }
    return null;
  };

  const cancelHandler = () => {
    modelComponentPositionManager.isRedirect = false;
    history.push(backUrl);
    return '';
  };

  const labelUnchecked = <FormattedMessage id="common.no" defaultMessage="No" />;
  const labelChecked = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  let messageId = (modelComponentPositionManager && modelComponentPositionManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const modelComponentPositionName =
    (modelComponentPositionManager.selectedModelComponentPosition && modelComponentPositionManager.selectedModelComponentPosition.Name) ||
    '';

  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'modelComponentPositions.edit', defaultMessage: 'Edit' }) + ' ' + modelComponentPositionName;
  } else {
    heading = intl.formatMessage({
      id: 'modelComponentPositions.addNewModelComponentPosition',
      defaultMessage: 'Add new model component position'
    });
  }

  const getModelSubComponentPositionData = () => {
    return modelComponentPositionManager.selectedModelComponentPosition.ModelComponentSubPositions || [];
  };

  if (modelComponentPositionManager.selectedModelComponentPosition.IsRaw) {
    history.push(backUrl);
  }

  if (
    IsEdit &&
    !modelComponentPositionManager.selectedModelComponentPosition.RawComponentName &&
    modelComponentPositionManager.selectedModelComponentPosition.HasRaw
  ) {
    setLocalModelComponentPosition({
      ...modelComponentPositionManager,
      selectedModelComponentPosition: {
        ...modelComponentPositionManager.selectedModelComponentPosition,
        RawComponentName: modelComponentPositionManager.selectedModelComponentPosition.Name + 'RAW'
      }
    });
  }

  const onChangeModelComponentPositionClick = modelSubComponentPositionId => {
    history.push(`/admin-controls/model-component/model-component-position/${modelSubComponentPositionId}/${modelComponentPositionId}`);
  };

  let ddlClassComponentType = localformErrors && localformErrors.ModelComponentTypeId ? s.ddlError : '';
  let ddlClassSubComponentType = localformErrors && localformErrors.ModelSubComponentTypeId ? s.ddlError : '';

  return (
    <div className={s.modelComponentPosition}>
      {modelComponentPositionManager.isLoading && <LoadingSpinner />}
      {isSubComponent && modelComponentPositionManager.isRedirect ? <Redirect to={backUrl} /> : ''}
      {!isSubComponent && modelComponentPositionManager.isParentRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={modelComponentPositionManager.showBanner}
        status={modelComponentPositionManager.isOpSuccessful}
        successText={messageText}
      />
      <AdminControlsContentTemplate selectedPage="manageComponents" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.modelComponentPositionHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="modelComponentPositions.back" defaultMessage="BACK" />
            </GoBack>
            <h3>{heading}</h3>
          </div>
          <div className={s.modelComponentPositionContent}>
            <div className={s.topRow}>
              <Form>
                {modelComponentPositionManager.selectedModelComponentPosition.ParentModelComponentPositionId && (
                  <Row>
                    <Col lg={4}>
                      <Form.Group controlId="formModelComponentPosition">
                        <Form.Label>
                          <FormattedMessage id="modelComponentPositions.parentComponent" defaultMessage="Parent Component" />
                        </Form.Label>
                        <Dropdown
                          id="ddlComponentType"
                          dataArray={parentComponentOptions()}
                          disabled={true}
                          controlData={{
                            placeholderText: (
                              <FormattedMessage id="modelComponentPositions.pleaseSelectOne" defaultMessage="Please select one" />
                            )
                          }}
                          onDropdownChange={onDropdownParentComponentPositionChange}
                          selectedOption={parentComponentOptions().filter(
                            option =>
                              option.value === modelComponentPositionManager.selectedModelComponentPosition.ParentModelComponentPositionId
                          )}
                          data-unittest="ddlParentComponentPosition"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formComponentType">
                      <Form.Label>
                        <FormattedMessage id="modelComponentPositions.componentType" defaultMessage="Component type" />
                      </Form.Label>
                      <Dropdown
                        id="ddlComponentType"
                        dataArray={componentOptions()}
                        controlData={{
                          placeholderText: (
                            <FormattedMessage id="modelComponentPositions.pleaseSelectOne" defaultMessage="Please select one" />
                          ),
                          customClassName: ddlClassComponentType
                        }}
                        disabled={
                          modelComponentPositionManager.selectedModelComponentPosition.ParentModelComponentPositionId != null ||
                          getModelSubComponentPositionData().length > 0
                        }
                        onDropdownChange={onDropdownComponentTypeChange}
                        selectedOption={componentOptions().filter(
                          option => option.value === modelComponentPositionManager.selectedModelComponentPosition.ModelComponentTypeId
                        )}
                        data-unittest="ddlComponentType"
                      />
                      {localformErrors && localformErrors.ModelComponentTypeId && (
                        <p role="alert" className={s.error}>
                          {localformErrors.ModelComponentTypeId}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                {modelComponentPositionManager.selectedModelComponentPosition.ParentModelComponentPositionId && (
                  <Row>
                    <Col lg={4}>
                      <Form.Group controlId="formSubcomponentType">
                        <Form.Label>
                          <FormattedMessage id="modelComponentPositions.subcomponentType" defaultMessage="Subcomponent type" />
                        </Form.Label>
                        <Dropdown
                          id="ddlSubcomponentType"
                          dataArray={subComponentOptions()}
                          controlData={{
                            placeholderText: (
                              <FormattedMessage id="modelComponentPositions.pleaseSelectOne" defaultMessage="Please select one" />
                            ),
                            customClassName: ddlClassSubComponentType
                          }}
                          // disabled={
                          //   modelComponentPositionManager.selectedModelComponentPosition.ParentModelComponentPositionId != null ||
                          //   getModelSubComponentPositionData().length > 0
                          // }
                          onDropdownChange={onDropdownSubcomponentTypeChange}
                          selectedOption={subComponentOptions().filter(
                            option => option.value === modelComponentPositionManager.selectedModelComponentPosition.ModelSubComponentTypeId
                          )}
                          data-unittest="ddlSubcomponentType"
                        />
                        {localformErrors && localformErrors.ModelSubComponentTypeId && (
                          <p role="alert" className={s.error}>
                            {localformErrors.ModelSubComponentTypeId}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formModelComponentPositionName">
                      <Form.Label>
                        <FormattedMessage id="modelComponentPositions.Name" defaultMessage="Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="ComponentName"
                        onChange={onChange}
                        disabled={getModelSubComponentPositionData().length > 0 || parentModelComponentPositionId}
                        value={modelComponentPositionManager.selectedModelComponentPosition.Name}
                        className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'modelComponentPositions.name',
                          defaultMessage: 'Name'
                        })}
                      />
                      {localformErrors && localformErrors.Name && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formModelComponentPositionTitle">
                      <Form.Label>
                        <FormattedMessage id="modelComponentPositions.title" defaultMessage="Title" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Title"
                        onChange={onChange}
                        value={modelComponentPositionManager.selectedModelComponentPosition.Title}
                        className={`${s.formControl} ${localformErrors && localformErrors.Title ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'modelComponentPositions.title',
                          defaultMessage: 'Title'
                        })}
                      />
                      {localformErrors && localformErrors.Title && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Title}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                {modelComponentPositionManager.selectedModelComponentPosition.ParentModelComponentPositionId && (
                  <Row>
                    <Col lg={4}>
                      <Form.Group controlId="formHasRaw">
                        <Form.Label>
                          <FormattedMessage id="modelComponentPositions.hasRaw" defaultMessage="Has a Raw Component" />
                        </Form.Label>
                        <ToggleSwitch
                          id="HasRaw"
                          handleClick={() => {
                            onToggleChange('HasRaw');
                          }}
                          classname={s.switch}
                          checked={modelComponentPositionManager.selectedModelComponentPosition.HasRaw}
                          labelChecked={labelChecked}
                          labelUnchecked={labelUnchecked}
                          labelPosition="Right"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {modelComponentPositionManager.selectedModelComponentPosition.ParentModelComponentPositionId &&
                  modelComponentPositionManager.selectedModelComponentPosition.HasRaw && (
                    <Row>
                      <Col lg={4}>
                        <Form.Group controlId="formModelComponentPositionRawComponentName">
                          <Form.Label>
                            <FormattedMessage id="modelComponentPositions.rawComponentName" defaultMessage="Raw Component Name" />
                          </Form.Label>

                          <Form.Control
                            type="text"
                            name="Raw Component Name"
                            value={modelComponentPositionManager.selectedModelComponentPosition.RawComponentName}
                            className={`${s.formControl} ${localformErrors && localformErrors.RawComponentName ? s.formControlError : ''}`}
                            placeholder={intl.formatMessage({
                              id: 'modelComponentPositions.rawComponentName',
                              defaultMessage: 'Raw Component Name'
                            })}
                            disabled={true}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formIsVirtual">
                      <Form.Label>
                        <FormattedMessage id="modelComponentPositions.isVirtual" defaultMessage="Is Virtual" />
                      </Form.Label>
                      <ToggleSwitch
                        id="IsVirtual"
                        handleClick={() => {
                          onToggleChange('IsVirtual');
                        }}
                        classname={s.switch}
                        checked={modelComponentPositionManager.selectedModelComponentPosition.IsVirtual}
                        labelChecked={labelChecked}
                        labelUnchecked={labelUnchecked}
                        labelPosition="Right"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formDisplayComponent">
                      <Form.Label>
                        <FormattedMessage id="modelComponentPositions.displayComponent" defaultMessage="Display Component" />
                      </Form.Label>
                      <ToggleSwitch
                        id="DisplayComponent"
                        handleClick={() => {
                          onToggleChange('DisplayComponent');
                        }}
                        classname={s.switch}
                        checked={modelComponentPositionManager.selectedModelComponentPosition.DisplayComponent}
                        labelChecked={labelChecked}
                        labelUnchecked={labelUnchecked}
                        labelPosition="Right"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formStaticComponent">
                      <Form.Label>
                        <FormattedMessage id="modelComponentPositions.staticComponent" defaultMessage="Is Static Component" />
                      </Form.Label>
                      <ToggleSwitch
                        id="IsStatic"
                        handleClick={() => {
                          onToggleChange('IsStatic');
                        }}
                        classname={s.switch}
                        checked={modelComponentPositionManager.selectedModelComponentPosition.IsStatic}
                        labelChecked={labelChecked}
                        labelUnchecked={labelUnchecked}
                        labelPosition="Right"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="formModelComponentPositionDescription">
                      <Form.Label>
                        <FormattedMessage id="modelComponentPositions.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'modelComponentPositions.description',
                          defaultMessage: 'Description'
                        })}
                        value={modelComponentPositionManager.selectedModelComponentPosition.Description || ''}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={2}>
                    <Form.Group controlId="formModelComponentPositionIsCCP">
                      <Form.Label>
                        <FormattedMessage id="modelComponentPositions.ccp" defaultMessage="CCP" />
                      </Form.Label>
                      <ToggleSwitch
                        id="IsCCP"
                        handleClick={() => {
                          onToggleChange('IsCCP');
                        }}
                        classname={s.switch}
                        checked={modelComponentPositionManager.selectedModelComponentPosition.IsCCP}
                        labelChecked={labelChecked}
                        labelUnchecked={labelUnchecked}
                        labelPosition="Right"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group controlId="formModelComponentPositionIsOCP">
                      <Form.Label>
                        <FormattedMessage id="modelComponentPositions.ocp" defaultMessage="OCP" />
                      </Form.Label>
                      <ToggleSwitch
                        id="IsOCP"
                        handleClick={() => {
                          onToggleChange('IsOCP');
                        }}
                        classname={s.switch}
                        checked={modelComponentPositionManager.selectedModelComponentPosition.IsOCP}
                        labelChecked={labelChecked}
                        labelUnchecked={labelUnchecked}
                        labelPosition="Right"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {modelComponentPositionManager.selectedModelComponentPosition.ParentModelComponentPositionId && (
                  <Fragment>
                    <Row>
                      <Col lg={2}>
                        <Form.Group controlId="formModelComponentPositionNeedState">
                          <Form.Label>
                            <FormattedMessage id="modelComponentPositions.state" defaultMessage="State" />
                          </Form.Label>
                          <ToggleSwitch
                            id="NeedState"
                            handleClick={() => {
                              onToggleChange('NeedState');
                            }}
                            classname={s.switch}
                            checked={modelComponentPositionManager.selectedModelComponentPosition.NeedState}
                            labelChecked={labelChecked}
                            labelUnchecked={labelUnchecked}
                            labelPosition="Right"
                            disabled={modelComponentPositionManager.selectedModelComponentPosition.NeedFeedback}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={2}>
                        <Form.Group controlId="formModelComponentPositionNeedFeedback">
                          <Form.Label>
                            <FormattedMessage id="modelComponentPositions.feedback" defaultMessage="Feedback" />
                          </Form.Label>
                          <ToggleSwitch
                            id="NeedFeedback"
                            handleClick={() => {
                              onToggleChange('NeedFeedback');
                            }}
                            classname={s.switch}
                            checked={modelComponentPositionManager.selectedModelComponentPosition.NeedFeedback}
                            labelChecked={labelChecked}
                            labelUnchecked={labelUnchecked}
                            labelPosition="Right"
                            disabled={modelComponentPositionManager.selectedModelComponentPosition.NeedState}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={2}>
                        <Form.Group controlId="formModelComponentPositionPublishToCloud">
                          <Form.Label>
                            <FormattedMessage id="modelComponentPositions.publishToCloud" defaultMessage="Publish Telemetry To Cloud" />
                          </Form.Label>
                          <ToggleSwitch
                            id="PublishToCloud"
                            handleClick={() => {
                              onToggleChange('PublishToCloud');
                            }}
                            classname={s.switch}
                            checked={modelComponentPositionManager.selectedModelComponentPosition.PublishToCloud}
                            labelChecked={labelChecked}
                            labelUnchecked={labelUnchecked}
                            labelPosition="Right"
                            disabled={
                              !modelComponentPositionManager.selectedModelComponentPosition.NeedFeedback &&
                              !modelComponentPositionManager.selectedModelComponentPosition.NeedState
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={2}>
                        <Form.Group controlId="formModelComponentPositionDerived">
                          <Form.Label>
                            <FormattedMessage id="modelComponentPositions.derived" defaultMessage="Derived" />
                          </Form.Label>
                          <ToggleSwitch
                            id="IsDerived"
                            handleClick={() => {
                              onToggleChange('IsDerived');
                            }}
                            classname={s.switch}
                            checked={modelComponentPositionManager.selectedModelComponentPosition.IsDerived}
                            labelChecked={labelChecked}
                            labelUnchecked={labelUnchecked}
                            labelPosition="Right"
                            disabled={
                              !modelComponentPositionManager.selectedModelComponentPosition.NeedFeedback &&
                              !modelComponentPositionManager.selectedModelComponentPosition.NeedState
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    {modelComponentPositionManager.selectedModelComponentPosition.ParentModelComponentPositionId &&
                      modelComponentPositionManager.selectedModelComponentPosition.IsDerived && (
                        <Fragment>
                          <Row>
                            <Col lg={6}>
                              <Form.Group controlId="formModelComponentPositionExpression">
                                <Form.Label>
                                  <FormattedMessage id="modelComponentPositions.expression" defaultMessage="Expression" />
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  name="Expression"
                                  onChange={onChange}
                                  value={modelComponentPositionManager.selectedModelComponentPosition.Expression || ''}
                                  className={`${s.formControl}`}
                                  placeholder="eg: {t_yourtelemetrycomponent} * {s_yourglobalsettingkey} "
                                />
                                {localformErrors && localformErrors.Expression && (
                                  <p role="alert" className={s.error}>
                                    {localformErrors.Expression}
                                  </p>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <Form.Group controlId="formaggregationType">
                                <Form.Label>
                                  <FormattedMessage id="modelComponentPositions.aggregationType" defaultMessage="Aggregation Type" />
                                </Form.Label>
                                <Dropdown
                                  id="ddlAggregationType"
                                  dataArray={aggregationTypeList}
                                  controlData={{
                                    placeholderText: (
                                      <FormattedMessage id="modelComponentPositions.pleaseSelectOne" defaultMessage="Please select one" />
                                    ),
                                    customClassName: ddlClassSubComponentType
                                  }}
                                  onDropdownChange={onDropdownAggregationTypeChange}
                                  selectedOption={aggregationTypeList.filter(
                                    option => option.value === modelComponentPositionManager.selectedModelComponentPosition.AggregationType
                                  )}
                                  data-unittest="ddlAggregationType"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          {modelComponentPositionManager.selectedModelComponentPosition.AggregationType && (
                            <Row>
                              <Col lg={4}>
                                <Form.Group controlId="formModelComponentPositionAggregationDatapoint">
                                  <Form.Label>
                                    <FormattedMessage
                                      id="modelComponentPositions.aggregationDatapoint"
                                      defaultMessage="Aggregation Datapoint"
                                    />
                                  </Form.Label>
                                  <InputNumber
                                    name="AggregationDatapoint"
                                    step="1"
                                    onInputChange={onChange}
                                    customClassName={s.formControl}
                                    value={modelComponentPositionManager.selectedModelComponentPosition.AggregationDatapoint?.toString()}
                                    id="AggregationDatapoint"
                                    placeholder={intl.formatMessage({
                                      id: 'modelComponentPositions.aggregationDatapoint',
                                      defaultMessage: 'Aggregation Datapoint'
                                    })}
                                  />
                                  {localformErrors && localformErrors.AggregationDatapoint && (
                                    <p role="alert" className={s.error}>
                                      {localformErrors.AggregationDatapoint}
                                    </p>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                          )}
                          {modelComponentPositionManager.selectedModelComponentPosition.AggregationType &&
                            modelComponentPositionManager.selectedModelComponentPosition.AggregationType == 5 && (
                              <Fragment>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group controlId="formModelComponentPositionWeightedStartPoint">
                                      <Form.Label>
                                        <FormattedMessage
                                          id="modelComponentPositions.weightedStartPoint"
                                          defaultMessage="Weighted Avg Start Point"
                                        />
                                      </Form.Label>
                                      <InputNumber
                                        name="WeightedStartPoint"
                                        step="1"
                                        onInputChange={onChange}
                                        customClassName={s.formControl}
                                        value={modelComponentPositionManager.selectedModelComponentPosition.WeightedStartPoint?.toString()}
                                        id="WeightedStartPoint"
                                        placeholder={intl.formatMessage({
                                          id: 'modelComponentPositions.weightedStartPoint',
                                          defaultMessage: 'Weighted Avg Start Point'
                                        })}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4}>
                                    <Form.Group controlId="formModelComponentPositionWeightedIncrement">
                                      <Form.Label>
                                        <FormattedMessage
                                          id="modelComponentPositions.weightedIncrement"
                                          defaultMessage="Weighted Avg Increment"
                                        />
                                      </Form.Label>
                                      <InputNumber
                                        name="WeightedIncrement"
                                        step=".1"
                                        onInputChange={onChange}
                                        customClassName={s.formControl}
                                        value={modelComponentPositionManager.selectedModelComponentPosition.WeightedIncrement?.toString()}
                                        id="WeightedIncrement"
                                        placeholder={intl.formatMessage({
                                          id: 'modelComponentPositions.weightedIncrement',
                                          defaultMessage: 'Weighted Avg Increment'
                                        })}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Fragment>
                            )}
                        </Fragment>
                      )}

                    <Row>
                      <Col lg={3}>
                        <Form.Group controlId="formPeriodicSummaryCycle">
                          <Form.Label>
                            <FormattedMessage id="modelComponentPositions.periodicSummaryCycle" defaultMessage="Periodic Summary Cycle" />
                          </Form.Label>
                          <ToggleSwitch
                            id="PeriodicSummaryCycle"
                            handleClick={() => {
                              onToggleChange('PeriodicSummaryCycle');
                            }}
                            classname={s.switch}
                            checked={modelComponentPositionManager.selectedModelComponentPosition.PeriodicSummaryCycle}
                            labelChecked={labelChecked}
                            labelUnchecked={labelUnchecked}
                            labelPosition="Right"
                            disabled={
                              modelComponentPositionManager.selectedModelComponentPosition.NeedFeedback ||
                              !modelComponentPositionManager.selectedModelComponentPosition.NeedState
                            }
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={3}>
                        <Form.Group controlId="formNeedPeriodicSummaryMin">
                          <Form.Label>
                            <FormattedMessage
                              id="modelComponentPositions.needPeriodicSummaryMin"
                              defaultMessage="Need Periodic Summary Min"
                            />
                          </Form.Label>
                          <ToggleSwitch
                            id="NeedPeriodicSummaryMin"
                            handleClick={() => {
                              onToggleChange('NeedPeriodicSummaryMin');
                            }}
                            classname={s.switch}
                            checked={modelComponentPositionManager.selectedModelComponentPosition.NeedPeriodicSummaryMin}
                            labelChecked={labelChecked}
                            labelUnchecked={labelUnchecked}
                            labelPosition="Right"
                            disabled={
                              modelComponentPositionManager.selectedModelComponentPosition.NeedState ||
                              !modelComponentPositionManager.selectedModelComponentPosition.NeedFeedback
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={3}>
                        <Form.Group controlId="formNeedPeriodicSummaryMax">
                          <Form.Label>
                            <FormattedMessage
                              id="modelComponentPositions.needPeriodicSummaryMax"
                              defaultMessage="Need Periodic Summary Max"
                            />
                          </Form.Label>
                          <ToggleSwitch
                            id="NeedPeriodicSummaryMax"
                            handleClick={() => {
                              onToggleChange('NeedPeriodicSummaryMax');
                            }}
                            classname={s.switch}
                            checked={modelComponentPositionManager.selectedModelComponentPosition.NeedPeriodicSummaryMax}
                            labelChecked={labelChecked}
                            labelUnchecked={labelUnchecked}
                            labelPosition="Right"
                            disabled={
                              modelComponentPositionManager.selectedModelComponentPosition.NeedState ||
                              !modelComponentPositionManager.selectedModelComponentPosition.NeedFeedback
                            }
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={3}>
                        <Form.Group controlId="formPeriodicSummaryVolume">
                          <Form.Label>
                            <FormattedMessage id="modelComponentPositions.periodicSummaryVolume" defaultMessage="Periodic Summary Volume" />
                          </Form.Label>
                          <ToggleSwitch
                            id="PeriodicSummaryVolume"
                            handleClick={() => {
                              onToggleChange('PeriodicSummaryVolume');
                            }}
                            classname={s.switch}
                            checked={modelComponentPositionManager.selectedModelComponentPosition.PeriodicSummaryVolume}
                            labelChecked={labelChecked}
                            labelUnchecked={labelUnchecked}
                            labelPosition="Right"
                            disabled={
                              modelComponentPositionManager.selectedModelComponentPosition.NeedState ||
                              !modelComponentPositionManager.selectedModelComponentPosition.NeedFeedback
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <Form.Group controlId="formPeriodicSummaryRunHours">
                          <Form.Label>
                            <FormattedMessage
                              id="modelComponentPositions.periodicSummaryRunHours"
                              defaultMessage="Periodic Summary Run Hours"
                            />
                          </Form.Label>
                          <ToggleSwitch
                            id="PeriodicSummaryRunHours"
                            handleClick={() => {
                              onToggleChange('PeriodicSummaryRunHours');
                            }}
                            classname={s.switch}
                            checked={modelComponentPositionManager.selectedModelComponentPosition.PeriodicSummaryRunHours}
                            labelChecked={labelChecked}
                            labelUnchecked={labelUnchecked}
                            labelPosition="Right"
                            disabled={
                              !modelComponentPositionManager.selectedModelComponentPosition.NeedState &&
                              !modelComponentPositionManager.selectedModelComponentPosition.NeedFeedback
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Fragment>
                )}
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formModelComponentPositionUoM">
                      <Form.Label>
                        <FormattedMessage id="modelComponentPositions.unitOfMeasure" defaultMessage="Unit of measure" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="NeedUnitOfMeasure"
                        onChange={onChange}
                        value={modelComponentPositionManager.selectedModelComponentPosition.NeedUnitOfMeasure}
                        className={s.formControl}
                        placeholder={intl.formatMessage({
                          id: 'modelComponentPositions.unitOfMeasure',
                          defaultMessage: 'Unit of measure'
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formModelComponentPositionQty">
                      <Form.Label>
                        <FormattedMessage id="modelComponentPositions.quantity" defaultMessage="Quantity" />
                      </Form.Label>
                      <InputNumber
                        name="Quantity"
                        step=".1"
                        onInputChange={onChange}
                        customClassName={s.formControl}
                        value={modelComponentPositionManager.selectedModelComponentPosition.Quantity?.toString()}
                        id="Quantity"
                        placeholder={intl.formatMessage({
                          id: 'modelComponentPositions.quantity',
                          defaultMessage: 'Quantity'
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formStepAdjustable">
                      <Form.Label>
                        <FormattedMessage id="modelComponentPositions.stepAdjustable" defaultMessage="Step Adjustable" />
                      </Form.Label>
                      <ToggleSwitch
                        id="StepAdjustable"
                        handleClick={() => {
                          onToggleChange('StepAdjustable');
                        }}
                        classname={s.switch}
                        checked={modelComponentPositionManager.selectedModelComponentPosition.StepAdjustable}
                        labelChecked={labelChecked}
                        labelUnchecked={labelUnchecked}
                        labelPosition="Right"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formStepValue">
                      <Form.Label>
                        <FormattedMessage id="modelComponentPositions.stepValue" defaultMessage="Step Value" />
                      </Form.Label>
                      <InputNumber
                        name="StepValue"
                        step=".1"
                        onInputChange={onChange}
                        customClassName={s.formControl}
                        value={modelComponentPositionManager.selectedModelComponentPosition.StepValue?.toString()}
                        id="StepValue"
                        placeholder={intl.formatMessage({
                          id: 'modelComponentPositions.stepValue',
                          defaultMessage: 'Step Value'
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formModelComponentPositionOperator">
                      <Form.Label>
                        <FormattedMessage id="modelComponentPositions.operator" defaultMessage="Operator" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Operator"
                        onChange={onChange}
                        value={modelComponentPositionManager.selectedModelComponentPosition.Operator || ''}
                        className={`${s.formControl}`}
                        placeholder={intl.formatMessage({
                          id: 'modelComponentPositions.operator',
                          defaultMessage: 'Operator'
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formModelComponentPositionActive">
                      <Form.Label>
                        <FormattedMessage id="modelComponentPositions.active" defaultMessage="Active" />
                      </Form.Label>
                      <ToggleSwitch
                        id="IsActive"
                        handleClick={() => {
                          onToggleChange('IsActive');
                        }}
                        classname={s.switch}
                        checked={modelComponentPositionManager.selectedModelComponentPosition.IsActive}
                        labelChecked={labelChecked}
                        labelUnchecked={labelUnchecked}
                        labelPosition="Right"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {IsEdit && modelComponentPositionManager.selectedModelComponentPosition.ParentModelComponentPositionId == null && (
                  <div>
                    <ModelSubComponentPositionListTable
                      ModelComponentPositionData={getModelSubComponentPositionData()}
                      ChangeModelComponentPosition={onChangeModelComponentPositionClick}
                      UpdateAllowed={true}
                      onAddNewBtnClick={onAddNewBtnClick}
                    />
                  </div>
                )}
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="modelComponentPositions.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="modelComponentPositions.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

ModelComponentPosition.defaultProps = {
  modelComponentPositionManager: {
    ...initialModelComponentPositionsState.modelComponentPositionManager
  }
};

ModelComponentPosition.propTypes = {
  saveModelComponentPosition: PropTypes.func.isRequired,
  changeModelComponentPosition: PropTypes.func.isRequired,
  modelComponentPositionManager: PropTypes.object.isRequired
};

export default injectIntl(ModelComponentPosition);
