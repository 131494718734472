import React from 'react';
import { Route, Switch } from 'react-router';
import Dashboard from '../components/Dashboard/DashboardContainer';
import NoRoute from '../components/NoRoute/NoRoute';
import Login from '../components/Login/LoginContainer';
import UpdatePassword from '../components/UpdatePassword/UpdatePasswordContainer';
import DataTrend from '../components/DataTrend/DataTrendContainer';
import UnitConfiguration from '../components/UnitConfiguration/UnitConfigurationContainer';
import ProcessFlow from '../components/ProcessFlow/ProcessFlowContainer';
import Events from '../components/Events/EventsContainer';
import auth from '../hocs/auth';
import noAuth from '../hocs/noAuth';
import GeneralSettings from '../components/Settings/Settings';
import ProfileSettings from '../components/ProfileSettings/ProfileSettingsContainer';
import AdminControls from '../components/AdminControls/AdminControlsContainer';
import OrganisationList from '../components/OrganisationList/OrganisationListContainer';
import Organisation from '../components/Organisation/OrganisationContainer';
import ProfileList from '../components/ProfileList/ProfileListContainer';
import Profile from '../components/Profile/ProfileContainer';
import UserList from '../components/UserList/UserListContainer';
import User from '../components/User/UserContainer';
import UnitReports from '../components/UnitReports/UnitReportsContainer';
import UnitUpload from '../components/UnitUpload/UnitUploadContainer';
import EventAction from '../components/EventAction/EventActionContainer';
import Alarms from '../components/Alarms/AlarmsContainer';
import PartsList from '../components/ComponentPartList/ComponentPartListContainer';
import PartDetails from '../components/ComponentPartDetails/ComponentPartDetailsContainer';
import Part from '../components/ComponentPart/ComponentPartContainer';
import ModelList from '../components/ModelList/ModelListContainer';
import Model from '../components/Model/ModelContainer';
import ModelConfiguration from '../components/ModelConfiguration/ModelConfigurationContainer';
import ModelVersionConfiguration from '../components/ModelVersionConfiguration/ModelVersionConfigurationContainer';
import ModelVersion from '../components/ModelVersion/ModelVersionContainer';
import ModelFirmwareVersion from '../components/ModelFirmwareVersion/ModelFirmwareVersionContainer';

import ChecklistTaskTemplates from '../components/ChecklistTasksTemplates/ChecklistTasksTemplatesContainer';
import ChecklistTemplate from '../components/ChecklistTemplate/ChecklistTemplateContainer';
import TaskTemplate from '../components/TaskTemplate/TaskTemplateContainer';
import TaskTemplateDetail from '../components/TaskTemplateDetail/TaskTemplateDetailContainer';

import CalibrationEquationList from '../components/CalibrationEquationList/CalibrationEquationListContainer';
import CalibrationEquation from '../components/CalibrationEquation/CalibrationEquationContainer';

import Checklists from '../components/Checklists/ChecklistsContainer';
import ChecklistDetail from '../components/ChecklistDetail/ChecklistDetailContainer';
import ChecklistTaskList from '../components/TasksAndChecklists/TasksAndChecklistsContainer';
import TaskList from '../components/TaskList/TasksContainer';
import Task from '../components/Task/TaskContainer';
import TaskDetail from '../components/TaskDetail/TaskDetailContainer';

import Calibration from '../components/Calibration/CalibrationContainer';
import CalibrationView from '../components/Calibration/CalibrationViewContainer';
import CalibrationList from '../components/CalibrationList/CalibrationListContainer';

import GeneralGlobalSettings from '../components/GeneralGlobalSettingsList/GeneralGlobalSettingsListContainer';
import GeneralGlobalSettingsDetail from '../components/GeneralGlobalSettingsDetail/GeneralGlobalSettingsDetailContainer';

import EventCodeList from '../components/EventCodeList/EventCodeListContainer';
import EventCode from '../components/EventCode/EventCodeContainer';
import ModelComponent from '../components/ModelComponent/ModelComponentContainer';
import ModelComponentType from '../components/ModelComponentType/ModelComponentTypeContainer';
import ApprovedComponent from '../components/ApprovedComponent/ApprovedComponentContainer';
import UnitConfigurationPartDetails from '../components/UnitConfigurationPartDetails/UnitConfigurationPartDetailsContainer';
import ApprovedComponentView from '../components/ApprovedComponentView/ApprovedComponentViewContainer';
import ModelComponentPosition from '../components/ModelComponentPosition/ModelComponentPositionContainer';
import ModelTelemetryLink from '../components/ModelTelemetryLink/ModelTelemetryLink';
import Maintenance from '../components/Maintenance/Maintenance';
import UnitTagOutHistory from '../components/UnitTagOutHistory/UnitTagOutHistory';
import CustomRoute from './CustomRoute';
import UnAuth401 from '../components/Unauthorised/Unauthorised';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';
import SchedulerList from '../components/SchedulerList/SchedulerList';
import MFAuthentication from '../components/MFAuthentication/MFAuthentication';
import Scheduler from '../components/Scheduler/Scheduler';
import UnitServiceDetail from '../components/UnitServiceDetail/UnitServiceDetail';
import UnitServiceDetailView from '../components/UnitServiceDetail/UnitServiceDetailView';
import UnitServiceList from '../components/UnitServiceList/UnitServiceList';
import DailySummaryReport from '../components/DailySummaryReport/DailySummaryReport';
import UnitSettings from '../components/UnitSettings/UnitSettings';
import UnitList from '../components/UnitList/UnitListContainer';
import TelemetryPartsList from '../components/TelemetryPartsList/TelemetryPartsList';
import NotificationTypes from '../components/Notifications/NotificationType/NotificationTypeList';
import NotificationTypeUser from '../components/Notifications/NotificationTypeUser/NotificationTypeUser';
import FAQ from '../components/Help/FAQ/FAQ';
import FAQControl from '../components/FAQAdmin/FAQControl/FAQControl';
import FAQCategory from '../components/FAQAdmin/FAQCategory/FAQCategory';
import FAQuestion from '../components/FAQAdmin/FAQuestion/FAQuestion';
import OrganisationConfigure from '../components/OrganisationConfigure/OrganisationControl/OrganisationControl';
import OrganisationRegion from '../components/OrganisationConfigure/OrganisationRegion/OrganisationRegion';
import OrganisationMoveUnits from '../components/OrganisationMoveUnits/MoveUnits';
import Notifications from '../components/Help/Notifications/Notifications';
import OrganisationHierarchy from '../components/OrganisationHierarchy/OrganisationHierarchy';

import ReleaseNoteList from '../components/ReleaseNote/ReleaseNoteList';
import ReleaseNote from '../components/ReleaseNote/ReleaseNote';
import ReleaseNoteView from '../components/ReleaseNoteView/ReleaseNoteView';
import SequenceVersion from '../components/SequenceVersion/SequenceVersionContainer';
import SequenceVersionDetail from '../components/SequenceVersionDetail/SequenceVersionDetailContainer';
import SequenceVersionFileDetail from '../components/SequenceVersionFileDetail/SequenceVersionFileDetailContainer';

import StaticDataListing from '../components/StaticData/StaticDataListing';
import StaticData from '../components/StaticData/StaticData';

import AddUnit from '../components/Unit/AddUnit';

import TermsAndConditions from '../components/Help/TermsAndConditions/TermsAndConditions';
import SignTermsAndConditions from '../components/Help/TermsAndConditions/SignTermsAndConditions';
import BuildVersion from '../components/Help/BuildVersion/BuildVersion';

const routes = (
  <Switch>
    <Route exact path="/login" component={noAuth(Login)} />
    <Route exact path="/update-password" component={noAuth(UpdatePassword)} />

    <CustomRoute exact path="/" component={auth(Dashboard)} pageAccessCode={'NAV_HOME'} accessCodes={['NAV_HOME']} />

    <CustomRoute exact path="/dashboard" component={auth(Dashboard)} pageAccessCode={'NAV_HOME'} accessCodes={['NAV_HOME']} />
    <CustomRoute exact path="/unit" component={auth(UnitList)} pageAccessCode={'NAV_UNITS'} accessCodes={['NAV_UNITS']} />
    <CustomRoute
      exact
      path="/unit/unit-upload"
      component={auth(UnitUpload)}
      pageAccessCode={'MYUNITSINDASHBOARD'}
      accessCodes={['UPLOAD_UNIT']}
    />

    <CustomRoute
      exact
      path="/account-settings/settings"
      component={auth(GeneralSettings)}
      pageAccessCode={'NAV_SETTINGS'}
      accessCodes={['NAV_SETTINGS']}
    />
    <CustomRoute
      exact
      path="/account-settings"
      component={auth(ProfileSettings)}
      pageAccessCode={'NAV_SETTINGS'}
      accessCodes={['NAV_SETTINGS']}
    />

    <CustomRoute exact path="/alarms" component={auth(Alarms)} pageAccessCode={'NAV_ALARMS'} accessCodes={['NAV_ALARMS']} />
    <CustomRoute
      exact
      path="/maintenance/parts"
      component={auth(PartsList)}
      pageAccessCode={'NAV_MAINTAINENCE'}
      accessCodes={['NAV_MAINTAINENCE']}
    />
    <CustomRoute
      exact
      path="/maintenance/parts/part-details/:ComponentPartId"
      component={auth(PartDetails)}
      pageAccessCode={'NAV_MAINTAINENCE'}
      accessCodes={['PARTSVIEW']}
    />
    <CustomRoute
      exact
      path="/maintenance/parts/part"
      component={auth(Part)}
      pageAccessCode={'NAV_MAINTAINENCE'}
      accessCodes={['PARTSADDNEW']}
    />
    <CustomRoute
      exact
      path="/maintenance/parts/part/:ComponentPartId"
      component={auth(Part)}
      pageAccessCode={'NAV_MAINTAINENCE'}
      accessCodes={['PARTSEDIT']}
    />
    <CustomRoute
      exact
      path="/organisation-hierarchy"
      component={auth(OrganisationHierarchy)}
      pageAccessCode={'NAV_ORGANISATIONHIERARCHY'}
      accessCodes={['NAV_ORGANISATIONHIERARCHY']}
    />
    <CustomRoute
      exact
      path="/admin-controls"
      component={auth(AdminControls)}
      pageAccessCode={'ADMINCONTROLS'}
      accessCodes={['ADMINCONTROLS']}
    />
    <CustomRoute
      exact
      path="/admin-controls/organisation-list"
      component={auth(OrganisationList)}
      pageAccessCode={'ORGANISATION_TAB'}
      accessCodes={['ORGANISATION_TAB']}
    />
    <CustomRoute
      exact
      path="/admin-controls/organisation"
      component={auth(Organisation)}
      pageAccessCode={'ORGANISATION_TAB'}
      accessCodes={['ORGANISATIONINSERT']}
    />
    <CustomRoute
      exact
      path="/admin-controls/organisation/:OrganisationId/:ParentOrganisationId"
      component={auth(Organisation)}
      pageAccessCode={'ORGANISATION_TAB'}
      accessCodes={['ORGANISATIONUPDATE']}
    />

    <CustomRoute
      exact
      path="/admin-controls/profile-list"
      component={auth(ProfileList)}
      pageAccessCode={'PROFILES_TAB'}
      accessCodes={['PROFILES_TAB']}
    />
    <CustomRoute
      exact
      path="/admin-controls/profile/:profileId"
      component={auth(Profile)}
      pageAccessCode={'PROFILES_TAB'}
      accessCodes={['PROFILEUPDATE']}
    />
    <CustomRoute
      exact
      path="/admin-controls/profile"
      component={auth(Profile)}
      pageAccessCode={'PROFILES_TAB'}
      accessCodes={['PROFILEINSERT']}
    />

    <CustomRoute
      exact
      path="/admin-controls/model-list"
      component={auth(ModelList)}
      pageAccessCode={'MODELS_TAB'}
      accessCodes={['MODELS_TAB']}
    />
    <CustomRoute exact path="/admin-controls/model" component={auth(Model)} pageAccessCode={'MODELS_TAB'} accessCodes={['MODEL_ADD']} />
    <CustomRoute
      exact
      path="/admin-controls/model/:ModelId/:Duplicate?"
      component={auth(Model)}
      pageAccessCode={'MODELS_TAB'}
      accessCodes={['MODEL_UPDATE', 'MODEL_DUPLICATE']}
    />
    <CustomRoute
      exact
      path="/admin-controls/model-configuration/:ModelId"
      component={auth(ModelConfiguration)}
      pageAccessCode={'MODELS_TAB'}
      accessCodes={['MODEL_CONFIGURE']}
    />
    <CustomRoute
      exact
      path="/admin-controls/model-configuration/:ModelId/model-version-configuration/:ModelVersionId"
      component={auth(ModelVersionConfiguration)}
      pageAccessCode={'MODELS_TAB'}
      accessCodes={['MODELVERSIONCONFIGURE']}
    />
    <CustomRoute
      exact
      path="/admin-controls/model-configuration/:ModelId/add"
      component={auth(ModelVersion)}
      pageAccessCode={'MODELS_TAB'}
      accessCodes={['MODELVERSIONADD']}
    />
    <CustomRoute
      exact
      path="/admin-controls/model-configuration/:ModelId/edit/:ModelVersionId"
      component={auth(ModelVersion)}
      pageAccessCode={'MODELS_TAB'}
      accessCodes={['MODELVERSIONUPDATE']}
    />
    <CustomRoute
      exact
      path="/admin-controls/model-configuration/:ModelId/edit/:ModelVersionId/:Duplicate"
      component={auth(ModelVersion)}
      pageAccessCode={'MODELS_TAB'}
      accessCodes={['MODELVERSIONDUPLICATE']}
    />
    <CustomRoute
      exact
      path="/admin-controls/model-configuration/:ModelId/firmware/add"
      component={auth(ModelFirmwareVersion)}
      pageAccessCode={'MODELS_TAB'}
      accessCodes={['MODELVERSIONADD']}
    />
    <CustomRoute
      exact
      path="/admin-controls/model-configuration/:ModelId/firmware/edit/:ModelFirmwareVersionId"
      component={auth(ModelFirmwareVersion)}
      pageAccessCode={'MODELS_TAB'}
      accessCodes={['MODELVERSIONUPDATE']}
    />
    <CustomRoute
      exact
      path="/admin-controls/model-configuration/:ModelId/telemetry-link/:TelemetryLinkId"
      component={auth(ModelTelemetryLink)}
      pageAccessCode={'MODELS_TAB'}
      accessCodes={['MODEL_TELEMETRY_LINKS_EDIT']}
    />

    <CustomRoute
      exact
      path="/admin-controls/model-configuration/:ModelId/telemetry-link"
      component={auth(ModelTelemetryLink)}
      pageAccessCode={'MODELS_TAB'}
      accessCodes={['MODEL_TELEMETRY_LINKS_ADD']}
    />

    <CustomRoute
      exact
      path="/admin-controls/user-list"
      component={auth(UserList)}
      pageAccessCode={'USERS_TAB'}
      accessCodes={['USERS_TAB']}
    />
    <CustomRoute exact path="/admin-controls/user" component={auth(User)} pageAccessCode={'USERS_TAB'} accessCodes={['USERINSERT']} />
    <CustomRoute
      exact
      path="/admin-controls/user/:userId"
      component={auth(User)}
      pageAccessCode={'USERS_TAB'}
      accessCodes={['USERUPDATE']}
    />
    <CustomRoute
      exact
      path="/admin-controls/calibration-equation-list"
      component={auth(CalibrationEquationList)}
      pageAccessCode={'CALIBRATIONS_TAB'}
      accessCodes={['CALIBRATIONS_TAB']}
    />
    <CustomRoute
      exact
      path="/admin-controls/calibration-equation/:CalibrationEquationId"
      component={auth(CalibrationEquation)}
      pageAccessCode={'CALIBRATIONS_TAB'}
      accessCodes={['CALIBRATIONEQUATIONEDIT']}
    />
    <CustomRoute
      exact
      path="/admin-controls/calibration-equation"
      component={auth(CalibrationEquation)}
      pageAccessCode={'CALIBRATIONS_TAB'}
      accessCodes={['CALIBRATIONEQUATIONADD']}
    />
    <CustomRoute
      exact
      path="/admin-controls/checklist-task-templates"
      component={auth(ChecklistTaskTemplates)}
      pageAccessCode={'TASKSCHECKLISTS_TAB'}
      accessCodes={['TASKSCHECKLISTS_TAB']}
    />
    <CustomRoute
      exact
      path="/admin-controls/checklist-template/:checklistTemplateId"
      component={auth(ChecklistTemplate)}
      pageAccessCode={'TASKSCHECKLISTS_TAB'}
      accessCodes={['CHECKLISTTEMPLATEVIEW', 'CHECKLISTTEMPLATEEDIT', 'CHECKLISTTEMPLATEADDNEW']}
    />
    <CustomRoute
      exact
      path="/admin-controls/task-template"
      component={auth(TaskTemplate)}
      pageAccessCode={'TASKSCHECKLISTS_TAB'}
      accessCodes={['TASKTEMPLATEADDNEW']}
    />
    <CustomRoute
      exact
      path="/admin-controls/task-template/:TaskTemplateId"
      component={auth(TaskTemplate)}
      pageAccessCode={'TASKSCHECKLISTS_TAB'}
      accessCodes={['TASKTEMPLATEEDIT']}
    />
    <CustomRoute
      exact
      path="/admin-controls/task-template-detail/:TaskTemplateId"
      component={auth(TaskTemplateDetail)}
      pageAccessCode={'TASKSCHECKLISTS_TAB'}
      accessCodes={['TASKTEMPLATEVIEW']}
    />

    <CustomRoute
      exact
      path="/unit/process-flow/:unitSerialNumber"
      component={auth(ProcessFlow)}
      pageAccessCode={'MYUNITSINDASHBOARD'}
      accessCodes={['DEVICESCHEMATICDIAGRAM', 'REALTIMESENSORS', 'EVENTLOGSUMMARY', 'IOTCOMMANDSSUMMARY']}
    />
    <CustomRoute
      exact
      path="/unit/data-trend-analysis/:unitSerialNumber"
      component={auth(DataTrend)}
      pageAccessCode={'DATATRENDANALYSIS'}
      accessCodes={['DATATRENDANALYSIS']}
    />
    <CustomRoute
      exact
      path="/unit/unit-configuration/:unitSerialNumber"
      component={auth(UnitConfiguration)}
      pageAccessCode={'UNIT_CONFIGURATION'}
      accessCodes={['UNIT_CONFIGURATION']}
    />
    <CustomRoute
      exact
      path="/unit/calibrations/:unitSerialNumber"
      component={auth(CalibrationList)}
      pageAccessCode={'UNIT_CALIBRATION'}
      accessCodes={['UNIT_CALIBRATION']}
    />
    <CustomRoute
      exact
      path="/unit/calibrations/:unitSerialNumber/view/:unitId/:calibrationId"
      component={auth(CalibrationView)}
      pageAccessCode={'UNIT_CALIBRATION'}
      accessCodes={['CALIBRATION_VIEW']}
    />
    <CustomRoute
      exact
      path="/unit/calibrations/:unitSerialNumber/add/:unitId"
      component={auth(Calibration)}
      pageAccessCode={'UNIT_CALIBRATION'}
      accessCodes={['CALIBRATION_ADD']}
    />
    <CustomRoute
      exact
      path="/unit/calibrations/:unitSerialNumber/edit/:unitId/:calibrationId"
      component={auth(Calibration)}
      pageAccessCode={'UNIT_CALIBRATION'}
      accessCodes={['CALIBRATION_EDIT']}
    />
    <CustomRoute
      exact
      path="/unit/unit-configuration/:unitSerialNumber/parts/part-details/:ComponentPartId"
      component={auth(UnitConfigurationPartDetails)}
      pageAccessCode={'UNIT_CONFIGURATION'}
      accessCodes={['UNIT_PARTS_VIEW']}
    />
    <CustomRoute
      exact
      path="/events/:unitSerialNumber"
      component={auth(Events)}
      pageAccessCode={'EVENTLOGSUMMARY'}
      accessCodes={['EVENTLOGSUMMARY']}
    />
    <CustomRoute
      exact
      path="/unit/reports/:unitSerialNumber"
      component={auth(UnitReports)}
      pageAccessCode={'UNITREPORT'}
      accessCodes={['UNITREPORT']}
    />
    <CustomRoute
      exact
      path="/events/action/:unitSerialNumber/:eventActionId"
      component={auth(EventAction)}
      pageAccessCode={'ALARMMANAGE'}
      accessCodes={['ALARMMANAGE']}
    />

    <CustomRoute
      exact
      path="/tasks-and-checklists"
      component={auth(ChecklistTaskList)}
      pageAccessCode={'NAV_TASKS'}
      accessCodes={['NAV_TASKS']}
    />
    <CustomRoute
      exact
      path="/tasks-and-checklists/tasklist"
      component={auth(TaskList)}
      pageAccessCode={'NAV_TASKS'}
      accessCodes={['TASKLIST']}
    />
    <CustomRoute exact path="/tasks-and-checklists/task" component={auth(Task)} pageAccessCode={'NAV_TASKS'} accessCodes={['TASKADDNEW']} />

    <CustomRoute
      exact
      path="/tasks-and-checklists/task/:TaskId"
      component={auth(Task)}
      pageAccessCode={'NAV_TASKS'}
      accessCodes={['TASKEDIT']}
    />
    <CustomRoute
      exact
      path="/tasks-and-checklists/task-detail/:TaskId"
      component={auth(TaskDetail)}
      pageAccessCode={'NAV_TASKS'}
      accessCodes={['TASKVIEW']}
    />

    <CustomRoute
      exact
      path="/tasks-and-checklists/checklists"
      component={auth(Checklists)}
      pageAccessCode={'NAV_TASKS'}
      accessCodes={['CHECKLISTLIST']}
    />
    <CustomRoute
      exact
      path="/tasks-and-checklists/checklist-detail/:checklistId"
      component={auth(ChecklistDetail)}
      pageAccessCode={'NAV_TASKS'}
      accessCodes={['CHECKLISTADDNEW', 'CHECKLISTEDIT', 'CHECKLISTVIEW']}
    />

    <CustomRoute
      exact
      path="/admin-controls/general-global-settings"
      component={auth(GeneralGlobalSettings)}
      pageAccessCode={'GLOBALSETTINGS_TAB'}
      accessCodes={['GLOBALSETTINGS_TAB']}
    />
    <CustomRoute
      exact
      path="/admin-controls/general-global-settings/:generalGlobalSettingId"
      component={auth(GeneralGlobalSettingsDetail)}
      pageAccessCode={'GLOBALSETTINGS_TAB'}
      accessCodes={['GENERALGLOBALSETTINGSUPDATE', 'GENERALGLOBALSETTINGSADDNEW', 'GENERALGLOBALSETTINGSCLONE']}
    />

    <CustomRoute
      exact
      path="/admin-controls/event-code-list"
      component={auth(EventCodeList)}
      pageAccessCode={'EVENTCODES_TAB'}
      accessCodes={['EVENTCODES_TAB']}
    />
    <CustomRoute
      exact
      path="/admin-controls/event-codes"
      component={auth(EventCode)}
      pageAccessCode={'EVENTCODES_TAB'}
      accessCodes={['EVENTCODEADDNEW']}
    />
    <CustomRoute
      exact
      path="/admin-controls/event-codes/:eventCodeId"
      component={auth(EventCode)}
      pageAccessCode={'EVENTCODES_TAB'}
      accessCodes={['EVENTCODEUPDATE']}
    />
    <CustomRoute
      exact
      path="/admin-controls/model-component/"
      component={auth(ModelComponent)}
      pageAccessCode={'MODELCOMPONET_TAB'}
      accessCodes={['MODELCOMPONET_TAB']}
    />
    <CustomRoute
      exact
      path="/admin-controls/model-component/model-component-type/"
      component={auth(ModelComponentType)}
      pageAccessCode={'MODELCOMPONET_TAB'}
      accessCodes={['MODELCOMPONENTTYPE_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/admin-controls/model-component/model-component-type/:modelComponentTypeId"
      component={auth(ModelComponentType)}
      pageAccessCode={'MODELCOMPONET_TAB'}
      accessCodes={['MODELCOMPONENTTYPE_EDIT']}
    />

    <CustomRoute
      exact
      path="/admin-controls/model-component/approved-component/"
      component={auth(ApprovedComponent)}
      pageAccessCode={'MODELCOMPONET_TAB'}
      accessCodes={['APPROVEDCOMPONENT_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/admin-controls/model-component/approved-component/:approvedComponentId"
      component={auth(ApprovedComponent)}
      pageAccessCode={'MODELCOMPONET_TAB'}
      accessCodes={['APPROVEDCOMPONENT_EDIT', 'APPROVEDCOMPONENT_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/admin-controls/model-component/approved-component-view/:approvedComponentId"
      component={auth(ApprovedComponentView)}
      pageAccessCode={'MODELCOMPONET_TAB'}
      accessCodes={['APPROVEDCOMPONENT_VIEW']}
    />

    <CustomRoute
      exact
      path="/admin-controls/model-component/model-component-position/:modelComponentPositionId"
      component={auth(ModelComponentPosition)}
      pageAccessCode={'MODELCOMPONET_TAB'}
      accessCodes={['MODELCOMPONENTPOSITION_EDIT', 'MODELCOMPONENTPOSITION_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/admin-controls/model-component/model-component-position/:modelComponentPositionId/:parentModelComponentPositionId"
      component={auth(ModelComponentPosition)}
      pageAccessCode={'MODELCOMPONET_TAB'}
      accessCodes={['MODELCOMPONENTPOSITION_EDIT', 'MODELCOMPONENTPOSITION_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/maintenance"
      component={auth(Maintenance)}
      pageAccessCode={'NAV_MAINTAINENCE'}
      accessCodes={['NAV_MAINTAINENCE']}
    />
    <CustomRoute
      exact
      path="/maintenance/tagout-history"
      component={auth(UnitTagOutHistory)}
      pageAccessCode={'NAV_MAINTAINENCE'}
      accessCodes={['TAGOUT_TAB']}
    />
    <CustomRoute
      exact
      path="/maintenance/service"
      component={auth(UnitServiceList)}
      pageAccessCode={'NAV_MAINTAINENCE'}
      accessCodes={['SERVICE_TAB']}
    />
    <CustomRoute
      exact
      path="/maintenance/telemetry"
      component={auth(TelemetryPartsList)}
      pageAccessCode={'NAV_MAINTAINENCE'}
      accessCodes={['TELEMETRY_TAB']}
    />
    <CustomRoute
      exact
      path="/unit/schedule/:unitSerialNumber"
      component={auth(SchedulerList)}
      pageAccessCode={'UNIT_SCHEDULE'}
      accessCodes={['UNIT_SCHEDULE']}
    />

    <CustomRoute
      exact
      path="/unit/schedule/:unitSerialNumber/add/:unitId"
      component={auth(Scheduler)}
      pageAccessCode={'UNIT_SCHEDULE'}
      accessCodes={['SCHEDULE_ADD']}
    />

    <CustomRoute
      exact
      path="/maintenance/service/:checklistId"
      component={auth(UnitServiceDetail)}
      pageAccessCode={'SERVICE_TAB'}
      accessCodes={['UNIT_SERVICE_ADDNEW']}
    />

    <CustomRoute
      exact
      path="/maintenance/service/:checklistId/:unitServiceId"
      component={auth(UnitServiceDetail)}
      pageAccessCode={'SERVICE_TAB'}
      accessCodes={['UNIT_SERVICE_EDIT']}
    />

    <CustomRoute
      exact
      path="/maintenance/service-view/:checklistId/:unitServiceId"
      component={auth(UnitServiceDetailView)}
      pageAccessCode={'SERVICE_TAB'}
      accessCodes={['UNIT_SERVICE_VIEW']}
    />

    <CustomRoute
      exact
      path="/unit/service/:unitSerialNumber"
      component={auth(UnitServiceList)}
      pageAccessCode={'UNIT_UNIT_SERVICE_TAB'}
      accessCodes={['UNIT_UNIT_SERVICE_TAB']}
    />

    <CustomRoute
      exact
      path="/unit/summary/:unitSerialNumber"
      component={auth(DailySummaryReport)}
      pageAccessCode={'DAILY_SUMMARY_DATA'}
      accessCodes={['DAILY_SUMMARY_DATA']}
    />
    <CustomRoute
      exact
      path="/unit/unit-settings/:unitSerialNumber"
      component={auth(UnitSettings)}
      pageAccessCode={'UNIT_SETTINGS'}
      accessCodes={['UNIT_SETTINGS']}
    />

    <CustomRoute
      exact
      path="/admin-controls/notifications"
      component={auth(NotificationTypes)}
      pageAccessCode={'NOTIFICATIONS_TAB'}
      accessCodes={['NOTIFICATIONS_TAB']}
    />

    <CustomRoute
      exact
      path="/admin-controls/notifications/notification-type-users/:notificationTypeId"
      component={auth(NotificationTypeUser)}
      pageAccessCode={'NOTIFICATIONS_TAB'}
      accessCodes={['NOTIFICATION_USERS_EDIT']}
    />
    <CustomRoute
      exact
      path="/admin-controls/notifications/notification-type-users/:notificationTypeId"
      component={auth(NotificationTypeUser)}
      pageAccessCode={'NOTIFICATIONS_TAB'}
      accessCodes={['NOTIFICATION_USERS_EDIT']}
    />

    <CustomRoute exact path="/help" component={auth(FAQ)} pageAccessCode={'NAV_HELP'} accessCodes={['NAV_HELP']} />

    <CustomRoute exact path="/admin-controls/faqs" component={auth(FAQControl)} pageAccessCode={'FAQ_TAB'} accessCodes={['FAQ_TAB']} />

    <CustomRoute
      exact
      path="/admin-controls/faqs/category"
      component={auth(FAQCategory)}
      pageAccessCode={'FAQ_TAB'}
      accessCodes={['FAQ_CATEGORY_ADD']}
    />

    <CustomRoute
      exact
      path="/admin-controls/faqs/category/:faqCategoryId"
      component={auth(FAQCategory)}
      pageAccessCode={'FAQ_TAB'}
      accessCodes={['FAQ_CATEGORY_EDIT']}
    />

    <CustomRoute
      exact
      path="/admin-controls/faqs/question"
      component={auth(FAQuestion)}
      pageAccessCode={'FAQ_TAB'}
      accessCodes={['FAQ_ADD']}
    />

    <CustomRoute
      exact
      path="/admin-controls/faqs/question/:faQuestionId"
      component={auth(FAQuestion)}
      pageAccessCode={'FAQ_TAB'}
      accessCodes={['FAQ_EDIT']}
    />

    <CustomRoute
      exact
      path="/admin-controls/faqs/question/:faQuestionId/:faqCategoryId"
      component={auth(FAQuestion)}
      pageAccessCode={'FAQ_TAB'}
      accessCodes={['FAQ_ADD']}
    />

    <CustomRoute
      exact
      path="/admin-controls/organisation-list/:organisationId/configure"
      component={auth(OrganisationConfigure)}
      pageAccessCode={'ORGANISATION_TAB'}
      accessCodes={['ORGANISATION_CONFIGURE']}
    />

    <CustomRoute
      exact
      path="/admin-controls/organisation-list/:organisationId/configure/region"
      component={auth(OrganisationRegion)}
      pageAccessCode={'ORGANISATION_TAB'}
      accessCodes={['ORGANISATION_REGION_ADD']}
    />

    <CustomRoute
      exact
      path="/admin-controls/organisation-list/:organisationId/configure/region/:organisationRegionId"
      component={auth(OrganisationRegion)}
      pageAccessCode={'ORGANISATION_TAB'}
      accessCodes={['ORGANISATION_REGION_EDIT']}
    />

    <CustomRoute
      exact
      path="/admin-controls/organisation-list/:organisationId/move-units"
      component={auth(OrganisationMoveUnits)}
      pageAccessCode={'ORGANISATION_TAB'}
      accessCodes={['ORGANISATIONMOVEUNITS']}
    />

    <CustomRoute
      exact
      path="/help/notifications"
      component={auth(Notifications)}
      pageAccessCode={'NAV_HELP'}
      accessCodes={['NOTIFICATIONS_HISTORY_TAB']}
    />

    <CustomRoute
      exact
      path="/admin-controls/release-notes"
      component={auth(ReleaseNoteList)}
      pageAccessCode={'RELEASENOTELIST'}
      accessCodes={['RELEASENOTELIST']}
    />

    <CustomRoute
      exact
      path="/admin-controls/release-notes/:releaseNoteId"
      component={auth(ReleaseNote)}
      pageAccessCode={'RELEASENOTELIST'}
      accessCodes={['RELEASENOTEMANAGE']}
    />

    <CustomRoute
      exact
      path="/release-notes"
      component={auth(ReleaseNoteView)}
      pageAccessCode={'NAV_RELEASENOTE'}
      accessCodes={['NAV_RELEASENOTE']}
    />

    <CustomRoute
      exact
      path="/admin-controls/model-configuration/:modelId/model-version-configuration/:modelVersionId/sequence-version"
      component={auth(SequenceVersion)}
      pageAccessCode={'MODELS_TAB'}
      accessCodes={['SEQUENCEVERSIONLIST']}
    />

    <CustomRoute
      exact
      path="/admin-controls/model-configuration/:modelId/model-version-configuration/:modelVersionId/sequence-version/:sequenceVersionId"
      component={auth(SequenceVersionDetail)}
      pageAccessCode={'MODELS_TAB'}
      accessCodes={['SEQUENCEVERSIONADD', 'SEQUENCEVERSIONUPDATE']}
    />

    <CustomRoute
      exact
      path="/admin-controls/model-configuration/:modelId/model-version-configuration/:modelVersionId/sequence-version/:sequenceVersionId/sequence-version-file/:sequenceVersionFileId"
      component={auth(SequenceVersionFileDetail)}
      pageAccessCode={'MODELS_TAB'}
      accessCodes={['SEQUENCEVERSIONADD', 'SEQUENCEVERSIONUPDATE']}
    />

    <CustomRoute
      exact
      path="/unit/static-data/:unitSerialNumber"
      component={auth(StaticDataListing)}
      pageAccessCode={'UNITSTATICDATALIST'}
      accessCodes={['UNITSTATICDATALIST']}
    />
    <CustomRoute
      exact
      path="/unit/static-data/:unitSerialNumber/view/:unitId"
      component={auth(StaticDataListing)}
      pageAccessCode={'UNITSTATICDATALIST'}
      accessCodes={['UNITSTATICDATALIST']}
    />
    <CustomRoute
      exact
      path="/unit/static-data/:unitSerialNumber/:unitId/:staticDataId"
      component={auth(StaticData)}
      pageAccessCode={'UNITSTATICDATALIST'}
      accessCodes={['UNITSTATICDATAADD', 'UNITSTATICDATAUPDATE']}
    />

    <CustomRoute exact path="/unit/add" component={auth(AddUnit)} pageAccessCode={'ADD_UNIT'} accessCodes={['MYUNITSINDASHBOARD']} />

    <Route exact path="/unauth" component={auth(UnAuth401)} />
    <Route exact path="/forgot-password" component={noAuth(ForgotPassword)} />
    <Route exact path="/multi-factor-auth" component={noAuth(MFAuthentication)} />

    <Route exact path="/terms-and-conditions" component={auth(TermsAndConditions)} pageAccessCode={'NAV_HELP'} />
    <Route exact path="/sign-terms-and-conditions" component={SignTermsAndConditions} />
    <Route exact path="/build-version" component={auth(BuildVersion)} />

    <Route component={noAuth(NoRoute)} />
  </Switch>
);

export default routes;
