import React, { useEffect, useState } from 'react';
import SensorToolTip from './SensorToolTip';
import svgpanzoom from 'svg-pan-zoom';
import './DeviceStyle.css';
import Tank01 from './Tank01';
import Tank02 from './Tank02';
let schematicDiagram = null;
const DeviceSchematicSvg = sensorData => {
  //Ball valves
  //===========
  let { ShowSensors, unitComponentParts } = sensorData;

  let bv01 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.bv01;
  let clsBv01 = bv01 === undefined ? '' : bv01 === 0 ? 'sen-red' : bv01 === 1 ? 'sen-green' : 'sen-amber';

  let bv02 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.bv02;
  let clsBv02 = bv02 === undefined ? '' : bv02 === 0 ? 'sen-red' : bv02 === 1 ? 'sen-green' : 'sen-amber';

  let bv03 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.bv03;
  let clsBv03 = bv03 === undefined ? '' : bv03 === 0 ? 'sen-red' : bv03 === 1 ? 'sen-green' : 'sen-amber';

  let bv04 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.bv04;
  let clsBv04 = bv04 === undefined ? '' : bv04 === 0 ? 'sen-red' : bv04 === 1 ? 'sen-green' : 'sen-amber';

  let bv05 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.bv05;
  let clsBv05 = bv05 === undefined ? '' : bv05 === 0 ? 'sen-red' : bv05 === 1 ? 'sen-green' : 'sen-amber';

  let bv06 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.bv06;
  let clsBv06 = bv06 === undefined ? '' : bv06 === 0 ? 'sen-red' : bv06 === 1 ? 'sen-green' : 'sen-amber';

  let bv07 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.bv07;
  let clsBv07 = bv07 === undefined ? '' : bv07 === 0 ? 'sen-red' : bv07 === 1 ? 'sen-green' : 'sen-amber';

  let bv08 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.bv08;
  let clsBv08 = bv08 === undefined ? '' : bv08 === 0 ? 'sen-red' : bv08 === 1 ? 'sen-green' : 'sen-amber';

  let bv09 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.bv09;
  let clsBv09 = bv09 === undefined ? '' : bv09 === 0 ? 'sen-red' : bv09 === 1 ? 'sen-green' : 'sen-amber';

  let bv10 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.bv10;
  let clsBv10 = bv10 === undefined ? '' : bv10 === 0 ? 'sen-red' : bv10 === 1 ? 'sen-green' : 'sen-amber';

  let bv11 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.bv11;
  let clsBv11 = bv11 === undefined ? '' : bv11 === 0 ? 'sen-red' : bv11 === 1 ? 'sen-green' : 'sen-amber';

  //Level sensors
  //=============
  let lt01 = sensorData && sensorData.SensorData && sensorData.SensorData.lt01;
  let clsLt01 = lt01 !== undefined ? (lt01 === 0 ? 'sen-red' : 'sen-green') : '';

  let lt02 = sensorData && sensorData.SensorData && sensorData.SensorData.lt02;
  let clsLt02 = lt02 !== undefined ? (lt02 === 0 ? 'sen-red' : 'sen-green') : '';

  let lt03 = sensorData && sensorData.SensorData && sensorData.SensorData.lt03;
  let clsLt03 = lt03 !== undefined ? (lt03 === 0 ? 'sen-red' : 'sen-green') : '';

  let lsl01 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.lsl01;
  let clsLsl01 = lsl01 !== undefined ? (lsl01 === 0 ? 'sen-red' : 'sen-green') : '';

  //H valves
  //========
  let hv01 = sensorData && sensorData.SensorData && sensorData.SensorData.hv01;
  let clsHv01 = hv01 === undefined ? '' : hv01 === 0 ? 'sen-red' : hv01 === 1 ? 'sen-green' : 'sen-amber';

  let hv02 = sensorData && sensorData.SensorData && sensorData.SensorData.hv02;
  let clsHv02 = hv02 === undefined ? '' : hv02 === 0 ? 'sen-red' : hv02 === 1 ? 'sen-green' : 'sen-amber';

  //Turbidity sensors
  //=================
  let tb01 = sensorData && sensorData.SensorData && sensorData.SensorData.tb01;
  let clsTb01 = tb01 !== undefined ? (tb01 === '' ? 'sen-red' : 'sen-green') : '';

  let tb02 = sensorData && sensorData.SensorData && sensorData.SensorData.tb02;
  let clsTb02 = tb02 !== undefined ? (tb02 === '' ? 'sen-red' : 'sen-green') : '';

  let tb03 = sensorData && sensorData.SensorData && sensorData.SensorData.tb03;
  let clsTb03 = tb03 !== undefined ? (tb03 === '' ? 'sen-red' : 'sen-green') : '';

  //CT
  //===
  let ct01 = sensorData && sensorData.SensorData && sensorData.SensorData.ct01;
  let clsCt01 = ct01 !== undefined ? (ct01 === '' ? 'sen-red' : 'sen-green') : '';

  let cs01 = sensorData && sensorData.SensorData && sensorData.SensorData.cs01;
  let clsCs01 = cs01 !== undefined ? (cs01 === '' ? 'sen-red' : 'sen-green') : '';

  //temp
  let tt01 = sensorData && sensorData.SensorData && sensorData.SensorData.tt01;
  let clsTt01 = tt01 !== undefined ? (tt01 === '' ? 'sen-red' : 'sen-green') : '';

  let tm01 = sensorData && sensorData.SensorData && sensorData.SensorData.tm01;
  let clsTm01 = tm01 !== undefined ? (tm01 === '' ? 'sen-red' : 'sen-green') : '';

  //Tank
  //====
  let lt01_tooltip = sensorData && sensorData.SensorData && sensorData.SensorData.lt01;
  let lt02_tooltip = sensorData && sensorData.SensorData && sensorData.SensorData.lt02;

  //Solenoid valves
  //==================
  let sv20 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.sv20;
  let clsSv20 = sv20 === undefined ? '' : sv20 === 0 ? 'sen-red' : sv20 === 1 ? 'sen-green' : 'sen-amber';

  let sv21 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.sv21;
  let clsSv21 = sv21 === undefined ? '' : sv21 === 0 ? 'sen-red' : sv21 === 1 ? 'sen-green' : 'sen-amber';

  //Directional valves
  //==================
  //DV01
  let dv01 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.dv01;

  let clsDv01_1 = 'sen-amber';
  let clsDv01_2 = 'sen-amber';
  let clsDv01 = 'sen-amber';

  if (dv01 === 1) {
    clsDv01_1 = 'sen-green';
    clsDv01_2 = 'sen-red';
    clsDv01 = 'sen-green';
  } else if (dv01 === 2) {
    clsDv01_1 = 'sen-red';
    clsDv01_2 = 'sen-green';
    clsDv01 = 'sen-green';
  } else if (dv01 === undefined) {
    clsDv01_1 = '';
    clsDv01_2 = '';
    clsDv01 = '';
  }

  //DV02
  let dv02 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.dv02;
  let clsDv02_1 = 'sen-amber';
  let clsDv02_2 = 'sen-amber';
  let clsDv02 = 'sen-amber';
  if (dv02 === 1) {
    clsDv02_1 = 'sen-green';
    clsDv02_2 = 'sen-red';
    clsDv02 = 'sen-green';
  } else if (dv02 === 2) {
    clsDv02_1 = 'sen-red';
    clsDv02_2 = 'sen-green';
    clsDv02 = 'sen-green';
  } else if (dv02 === undefined) {
    clsDv02_1 = '';
    clsDv02_2 = '';
    clsDv02 = '';
  }

  //DV03
  let dv03 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.dv03;
  let clsDv03_1 = 'sen-amber';
  let clsDv03_2 = 'sen-amber';
  let clsDv03 = 'sen-amber';

  if (dv03 === 1) {
    clsDv03_1 = 'sen-green';
    clsDv03_2 = 'sen-red';
    clsDv03 = 'sen-green';
  } else if (dv03 === 2) {
    clsDv03_1 = 'sen-red';
    clsDv03_2 = 'sen-green';
    clsDv03 = 'sen-green';
  } else if (dv03 === undefined) {
    clsDv03_1 = '';
    clsDv03_2 = '';
    clsDv03 = '';
  }

  // FT
  let ft01 = sensorData && sensorData.SensorData && sensorData.SensorData.ft01;
  let clsFt01 = ft01 !== undefined ? (ft01 === 0 ? 'sen-red' : 'sen-green') : '';

  let ft02 = sensorData && sensorData.SensorData && sensorData.SensorData.ft02;
  let clsFt02 = ft02 !== undefined ? (ft02 === 0 ? 'sen-red' : 'sen-green') : '';

  // Pump P
  let p01 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.p01;
  let clsP01 = p01 !== undefined ? (p01 === 0 ? 'sen-red' : 'sen-green') : '';

  let p02 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.p02;
  let clsP02 = p02 !== undefined ? (p02 === 0 ? 'sen-red' : 'sen-green') : '';

  let p03 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.p03;
  let clsP03 = p03 !== undefined ? (p03 === 0 ? 'sen-red' : 'sen-green') : '';

  let p04 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.p04;
  let clsP04 = p04 !== undefined ? (p04 === 0 ? 'sen-red' : 'sen-green') : '';

  // Pressure Pump
  let pt01 = sensorData && sensorData.SensorData && sensorData.SensorData.pt01;
  let clsPt01 = pt01 !== undefined ? (pt01 === 0 ? 'sen-red' : 'sen-green') : '';

  let pt02 = sensorData && sensorData.SensorData && sensorData.SensorData.pt02;
  let clsPt02 = pt02 !== undefined ? (pt02 === 0 ? 'sen-red' : 'sen-green') : '';

  let pt03 = sensorData && sensorData.SensorData && sensorData.SensorData.pt03;
  let clsPt03 = pt03 !== undefined ? (pt03 === 0 ? 'sen-red' : 'sen-green') : '';

  // UV Receiver and Transmitter

  let uvi01 = sensorData && sensorData.SensorData && sensorData.SensorData.uvi01;
  let clsUvi01 = uvi01 !== undefined ? (uvi01 === 0 ? 'sen-red' : 'sen-green') : '';

  let uvd01 = sensorData && sensorData.SensorData && sensorData.SensorData.uvd01;
  let clsUvd01 = uvd01 !== undefined ? (uvd01 === 0 ? 'sen-red' : 'sen-green') : '';

  const svgElement = document.querySelector('#dv-schematics');
  var options = {
    zoomEnabled: true,
    mouseWheelZoomEnabled: sensorData.enableMouseZoom,
    controlIconsEnabled: true,
    fit: true,
    center: true,
    contain: true
  };

  if (svgElement) {
    schematicDiagram = svgpanzoom(svgElement, options);

    if (sensorData.enableMouseZoom) {
      schematicDiagram.enableMouseWheelZoom();
    } else {
      schematicDiagram.disableMouseWheelZoom();
    }
  }

  const onResize = () => {
    if (schematicDiagram) {
      // schematicDiagram.reset();
      schematicDiagram.resize();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
  }, [onResize]); // Only initialize listener on initial render

  return (
    <svg id="dv-schematics" x={0} y={0} viewBox="0 0 1190.55 841.89" xmlSpace="preserve">
      <text transform="translate(118.725 171.721)" fontSize={28.449} fontFamily="ArialMT" />
      <path
        className="st3"
        d="M313.97 318.99h168.21M714.45 331.69h-14.82m14.82-3.67h-14.82M707.12 331.69v36.83H683.13M679.46 375.86v-14.68m3.67 14.68v-14.68M679.46 484.8v-14.68m3.67 14.68v-14.68M683.13 477.46h10.72M714.74 523.19h-14.68m14.68-3.67h-14.68M707.4 519.52v-10.73M707.4 523.19v9.73M630.66 358.36v10.16M654.76 190.72h-27.37M679.46 368.52h-65.34M878.5 592.47h88.89"
      />
      <path className="st3" d="M878.43 560.73V267.59H614.12M822.13 555.36h56.1m-259.1 72.39h169v-72.39h23.97M487 627.75h121.99" />
      <path className="st3" d="M608.9,627.5c0-2.8,2.3-5.1,5.1-5.1s5.1,2.3,5.1,5.1" />
      <path className="st3" d="M811.9,555.1c0-2.8,2.3-5.1,5.1-5.1s5.1,2.3,5.1,5.1" />
      <path className="st3" d="M476.9,592.1c0-2.8,2.3-5.1,5.1-5.1s5.1,2.3,5.1,5.1" />
      <path className="st3" d="M476.9,627.5c0-2.8,2.3-5.1,5.1-5.1s5.1,2.3,5.1,5.1" />

      <path className="st3" d="M817.33 665.86V319.13H707.12v8.89M614.12 590.78h93.28v-24.83M614.12 665.86V540.29M614.12 530.29V259.99" />

      <path className="st3" d="M266.7 368.8V289.64h-30.76" />
      <polyline className="st3 polyLineDotted" points="266.7,369.8 266.7,665.86 967.67,665.86 " />

      <path className="st3" d="M236.07 262.89v99.85h72.9" />
      <path className="st3" d="M172.7 143.44h63.37v98.91" />
      <path className="st3" d="M313.97 246.46v109.8" />
      <path className="st3" d="M313.97 358.7v269.49h15.45" />
      <path className="st3" d="M355.76 627.75h121.11" />

      <path className="st3" d="M319.1 362.74h114.7v-26.39h48.12M560.78 375.65v216.82H486.9" />
      <path className="st3" d="M308.9,362.74c0-2.8,2.3-5.1,5.1-5.1c2.8,0,5.1,2.3,5.1,5.1" />

      <path className="st3" d="M482.18 665.86V300.93h78.6v48.33" />

      <path className="st3" d="M482.18 506.39h-45.85v86.08h40.73" />

      <path className="st3" d="M588.96 506.11h25.20m-53.34 0h18" />
      <path className="st3" d="M578.66,506.11c0-2.8,2.3-5.1,5.1-5.1s5.1,2.3,5.1,5.1" />

      <path className="st3" d="M614.1,530.3c2.8,0,5.1,2.3,5.1,5.1c0,2.8-2.3,5.1-5.1,5.1" />
      <path className="st3" d="M707.4 538.57v30.15" />
      <path className="st3" d="M967.39 627.75h-88.96v-40.68" />

      <path fill="none" stroke="#000" strokeWidth={0.5} strokeLinejoin="round" strokeDasharray="4.019,3.0142" d="M663 535.46h-75.85" />
      <path className="st4" d="M585.64 535.46h-2v-2" />
      <path fill="none" stroke="#000" strokeWidth={0.5} strokeLinejoin="round" strokeDasharray="4.0206,3.0155" d="M583.64 530.45V194.22" />
      <path className="st4" d="M583.64 192.72v-2h2" />
      <path fill="none" stroke="#000" strokeWidth={0.5} strokeLinejoin="round" strokeDasharray="4.1392,3.1044" d="M588.74 190.72h34.67" />
      <path className="st4" d="M624.96 190.72h2M704.58 535.46h-2" />
      <path fill="none" stroke="#000" strokeWidth={0.5} strokeLinejoin="round" strokeDasharray="3.4749,2.6062" d="M699.97 535.46h-10.86" />
      <path className="st9" d="M614.18 232.74V190.5" />
      <path className="st3" d="M606.43 579.86h7.69" />
      <text transform="translate(206.03 154.109)" className="st10 st11">
        {'BV01'}
      </text>

      <text transform="translate(205.425 320.85)" className="st10 st11">
        {'BV11'}
      </text>
      <text transform="translate(620.499 558.472)" className="st10 st11">
        {'BV02'}
      </text>
      <text transform="translate(713.541 564.663)" className="st10 st11">
        {'BV03'}
      </text>
      <text transform="translate(713.541 537.638)" className="st10 st11">
        {'SG01'}
      </text>
      <text transform="translate(924.735 639.241)" className="st10 st11">
        {'BV06'}
      </text>
      <text transform="translate(620.499 435.839)" className="st10 st11">
        {'BV07'}
      </text>
      <g id="g-sv21">
        <text transform="translate(551.765 215.855)" className="st10 st11">
          {'SV21'}
        </text>
        <path className={`st3 ${clsSv21} `} d="M576.53 213.96h7.2-7.2z" />
        <path className={`st12 ${clsSv21}`} d="M583.73 213.96l-6.66 13.23h13.34z" />
        <path className={`st12 ${clsSv21}`} d="M583.73 213.96l6.68-13.23h-13.34z" />
        <path className={`st12 ${clsSv21}`} d="M565.95 208.65h10.59v10.59h-10.59z" />
      </g>
      <text transform="translate(621.176 215.855)" className="st10 st11">
        {'SV20'}
      </text>
      <text transform="translate(636.838 170.329)" className="st10 st11">
        {'P02'}
      </text>

      <g id="g-sv20">
        <path className={`st3 ${clsSv20}`} d="M607.28 213.96h7.2-7.2z" />
        <path className={`st12 ${clsSv20}`} d="M614.48 213.96l-6.67 13.23h13.33z" />
        <path className={`st12 ${clsSv20}`} d="M614.48 213.96l6.66-13.23h-13.33z" />
        <path className={`st12 ${clsSv20}`} d="M596.7 208.66h10.59v10.59H596.7z" />
      </g>
      <text transform="translate(670.75 279.626)" className="st10 st11">
        {'BV05'}
      </text>
      <text transform="translate(620.197 648.85)" className="st10 st11">
        {'BV09'}
      </text>
      <text transform="translate(247.156 320.85)" className="st10 st11">
        {'HV01'}
      </text>

      <Tank01 LT01={lt01} LSL01={lsl01} BV01={bv01} unitComponentParts={unitComponentParts} />

      <g id="g-lsl01">
        <circle className={`st3 ${clsLsl01}`} cx={220.46} cy={245.19} r={8.89} />
        <path className="st3" d="M211.57 245.19h17.78" />
      </g>
      <text transform="translate(213.262 250.586)" className="st10 st11">
        {'LSL01'}
      </text>

      <g id="g-pt01">
        <circle id="pt01" className={`st12 ${clsPt01}`} cx={412.28} cy={299.73} r={8.89} />
      </g>
      <text transform="translate(407.342 305.236)" className="st10 st11">
        {'PT01'}
      </text>
      <g id="g-tb03">
        <circle className={`st12 ${clsTb03}`} cx={733.29} cy={267.63} r={8.89} />
        <text transform="translate(728.158 273.595)" className="st10 st11">
          {'TB03'}
        </text>
      </g>

      <g id="g-uvi01">
        <circle id="uvi01" className={`st12 ${clsUvi01}`} cx={900.71} cy={325.46} r={8.89} />
        <path className="st3" d="M887.82 325.46h21.79" />
        <text transform="translate(893.3 330.411)" className="st10 st11">
          {'UVI01'}
        </text>
      </g>

      <text transform="translate(820.753 355.984)" className="st10 st11">
        {'BV08'}
      </text>
      <text transform="translate(893.348 306.404)" className="st10 st11">
        {'UV01'}
      </text>
      <g id="g-pt03">
        <circle id="pt03" className={`st12 ${clsPt03}`} cx={630.59} cy={349.54} r={8.89} />
        <text transform="translate(626.495 355.05)" className="st10 st11">
          {'PT03'}
        </text>
      </g>

      <g id="g-tb02">
        <circle className={`st12 ${clsTb02}`} cx={511.27} cy={592.47} r={8.89} />
        <path className="st3" d="M502.39 592.47h17.79" />

        <text transform="translate(504.80 597.983)" className="st10 st11">
          {'TB02'}
        </text>
      </g>

      <g id="g-ct01">
        <circle className={`st12 ${clsCt01} `} cx={545.73} cy={592.47} r={8.89} />
        <text transform="translate(539.476 597.983)" className="st10 st11">
          {'CT01'}
        </text>
      </g>

      <g id="g-pt02">
        <circle className={`st12 ${clsPt02}`} cx={597.54} cy={579.86} r={8.89} />
        <text transform="translate(591.835 585.363)" className="st10 st11">
          {'PT02'}
        </text>
      </g>
      <g id="g-tt01">
        <circle className={`st12 ${clsTt01}`} cx={511.27} cy={611.26} r={8.89} />
        <text transform="translate(504.80 615.766)" className="st10 st11">
          {'TT01'}
        </text>
        <path className="st3" d="M502.39 610.26h17.78" />
      </g>
      <text transform="translate(438.053 304.115)" className="st10 st11">
        {'FT01'}
      </text>
      <text transform="translate(491.43 320.85)" className="st10 st11">
        {'DV02'}
      </text>
      <text transform="translate(501.995 364.445)" className="st10 st11">
        {'F01'}
      </text>
      <text transform="translate(364.813 293.896)" className="st10 st11">
        {'P01'}
      </text>
      <text transform="translate(352.566 374.024)" className="st10 st11">
        {'BV10'}
      </text>
      <path className="st3" d="M412.37 308.55v10.44" />
      <g id="g-ft01">
        <path id="ft01-bx" className={`st12 ${clsFt01}`} d="M427.58 308.31h31.67v21.34h-31.67z" />
        <circle id="ft01-c1" className={`st12 ${clsFt01}`} cx={437.09} cy={318.98} r={6.34} />
        <circle id="ft01-c2" className={`st12 ${clsFt01}`} cx={449.75} cy={318.98} r={6.34} />
      </g>
      <g id="g-internalToSystem">
        <text transform="translate(88.4 553)" className="st10 st11">
          Internal to System
        </text>
        <rect x={70.4} y={563.1} className="st20" width="79.5" height="95.2" />
        <g id="g-cs01">
          <circle className={`st12  ${clsCs01}`} cx={109.6} cy={592.6} r={8.89} />
          <text transform="translate(103.476 597.983)" className="st10 st11">
            {'CS01'}
          </text>
          <path className="st3" d="M100.6 592.6h17.78" />
        </g>

        <g id="g-tm01">
          <circle className={`st12  ${clsTm01}`} cx={109.6} cy={622.6} r={8.89} />
          <text transform="translate(103.476 627.983)" className="st10 st11">
            {'TM01'}
          </text>
          <path className="st3" d="M100.6 622.6h17.78" />
        </g>
      </g>

      <text transform="translate(893.348 410.33)" className="st10 st11">
        {'FT02'}
      </text>
      <g id="g-ft02">
        <path id="ft02-bx" className={`st12 ${clsFt02}`} d="M869.55 393.71h17.78v26.39h-17.78z" />
        <circle id="ft02-c1" className={`st12 ${clsFt02}`} cx={878.43} cy={412.18} r={5.28} />
        <circle id="ft02-c2" className={`st12 ${clsFt02}`} cx={878.43} cy={401.63} r={5.28} />
      </g>

      <path className="st12" d="M464.54 345.22h35.28v35.28h-35.28z" />
      <path className="st9" d="M464.54 345.22l35.28 35.28" />
      <text transform="translate(501.995 414.118)" className="st10 st11">
        {'F02'}
      </text>
      <path className="st12" d="M464.54 394.89h35.28v35.28h-35.28z" />
      <path className="st9" d="M464.54 394.89l35.28 35.28" />
      <text transform="translate(501.995 465.675)" className="st10 st11">
        {'F03'}
      </text>
      <path className="st12" d="M464.54 446.45h35.28v35.28h-35.28z" />
      <path className="st9" d="M464.54 446.45l35.28 35.28" />

      <g id="g-nrv01" transform="">
        <path className="st3" d="M329.42 621.77v13.34l26.34 -13.34v13.34" />
        <circle className="st12" cx={329.42} cy={634.77} r={1.38} />
        <text transform="translate(336.076 619.549)" className="st10 st11">
          {'NRV01'}
        </text>
      </g>
      <text transform="translate(537.688 364.08)" className="st10 st11">
        {'NRV02'}
      </text>
      <path className="st3" d="M568.13 375.6H554.8l13.33-26.34h-13.27" />
      <circle className="st12" cx={554.8} cy={375.6} r={1.38} />
      <text transform="translate(670.428 527.64)" className="st10 st11">
        {'NRV05'}
      </text>
      <path className="st3" d="M689.19 528.1v13.34l-26.33-13.34v13.28" />
      <circle className="st12" cx={689.19} cy={541.44} r={1.38} />
      <text transform="translate(620.94 248.449)" className="st10 st11">
        {'NRV04'}
      </text>
      <text transform="translate(652.472 379.276)" className="st10 st11">
        {'BV04'}
      </text>
      <path className="st3" d="M621.53 259.12H608.2l13.33-26.34H608.2" />
      <circle className="st12" cx={608.2} cy={259.12} r={1.38} />
      <g id="g-p02">
        <path
          id="p02"
          className={`st12 ${clsP02}`}
          d="M649.54 181.86c-.02-4.85-3.96-8.78-8.82-8.78s-8.8 3.93-8.82 8.78h-.01v18.67h.06c.48 4.42 4.22 7.86 8.77 7.86s8.29-3.44 8.77-7.86h.05v-18.67z"
        />
        <circle className={`st12 ${clsP02}`} cx={640.72} cy={181.9} r={8.82} />
        <circle className={`st12 ${clsP02}`} cx={640.72} cy={199.56} r={8.82} />
      </g>
      <g id="ambientAir">
        <polygon className="st12" points="729.1,180.4 678.6,180.4 668.5,190.5 678.6,200.5 729.1,200.5 719,190.5" />
        <path className="st12" d="M668.5 190.73h-39.61" />
        <text transform="translate(679 192)" className="st10 st11">
          {'Ambient Air'}
        </text>
      </g>

      <path
        className="st12"
        d="M707.12 337.92c-7.29 0-13.19 5.91-13.19 13.19v144.22c0 7.29 5.91 13.19 13.19 13.19s13.19-5.91 13.19-13.19V351.11c0-7.28-5.91-13.19-13.19-13.19z"
      />
      <path className="st3" d="M693.92 357.92h26.39M693.92 488.53h26.39" />
      <text transform="translate(701.479 424.811)" className="st10 st11">
        {'UF01'}
      </text>
      <path className="st12" d="M704.57 532.92h5.64v5.64h-5.64z" />
      <path
        fill="none"
        stroke="#000"
        strokeWidth={0.5}
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray="1,1"
        d="M704.58 532.92l5.64 5.65"
      />
      <text transform="translate(795.3 247.42)" className="st10 st11">
        {'F05'}
      </text>
      <text transform="translate(995.946 194.33)" className="st10" fontSize={7}>
        {'External to System'}
      </text>

      <path className="st12" d="M781.7 249.99h35.28v35.28H781.7z" />
      <path className="st9" d="M781.77 249.99l35.28 35.28" />
      <Tank02 LT02={lt02} unitComponentParts={unitComponentParts} />

      <g id="g-lt02">
        <circle className={`st12 ${clsLt02}`} cx={923.24} cy={461.76} r={8.89} />
        <text transform="translate(918.34 467.269)" className="st10 st11">
          {'LT02'}
        </text>
      </g>
      <text transform="translate(885.901 576.458)" className="st10 st11">
        {'NRV03'}
      </text>
      <path className="st3" d="M885.33 587.07H872l13.33-26.34h-13.29" />
      <circle className="st12" cx={872} cy={587.07} r={1.38} />
      <text transform="translate(925.149 582.84)" className="st10 st11">
        {'HV02'}
      </text>

      <g id="inputWater">
        <path className="st12" d="M128.45 143.43l-8.81 8.89h44.17l8.89-8.89-8.89-8.89h-44.17z" />
        <text transform="translate(135.568 145.162)" className="st10 st11">
          {'Input Water'}
        </text>
      </g>

      <g id="sampleWater">
        <polygon className="st12" points="957.3,602.1 1007.7,602.1 1017.8,592 1007.7,581.9 957.3,581.9 967.3,592" />
        <text transform="translate(977.294 594.177)" className="st10 st11">
          {'Sample'}
        </text>
      </g>

      <g id="outputWater">
        <polygon className="st12" points="957.3,638.1 1007.7,638.1 1017.8,628 1007.7,617.9 957.3,617.9 967.3,628" />
        <text transform="translate(973.073 629.465)" className="st10 st11">
          {'Output Water'}
        </text>
      </g>

      <g id="flushWater">
        <polygon className="st12" points="957.3,676.1 1007.7,676.1 1017.8,666 1007.7,655.9 957.3,655.9 967.3,666" />
        <text transform="translate(973.698 667.873)" className="st10 st11">
          Flush Water
        </text>
      </g>

      <g id="g-p04">
        <circle className={`st12 ${clsP04}`} cx={1018.44} cy={278.86} r={22.5} />
        <path id="p04" className={`st12 ${clsP04}`} d="M1018.44 301.35l22.5-22.49-22.5-22.5" />
      </g>

      <path className="st3" d="M995.95 278.86h-11.29M1052.21 278.86h-11.27" />
      <text transform="translate(1014.698 282.873)" className="st10 st11">
        {'P04'}
      </text>
      <path
        fill="none"
        stroke="#02b04e"
        strokeWidth={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray="4,3"
        d="M970.76 207.34h94.2v110.63h-94.2z"
      />

      <g id="g-lt01">
        <circle className={`st3 ${clsLt01}`} cx={327.72} cy={181.97} r={8.89} />
        <path className="st3" d="M318.83 181.97h17.79" />
      </g>
      <text transform="translate(322.87 187.63)" className="st10 st11">
        {'LT01'}
      </text>

      <g id="g-tb01">
        <circle className={`st12 ${clsTb01}`} cx={313.97} cy={283.23} r={8.89} />
      </g>

      <path className="st3" d="M305.09 283.23h17.78" />
      <path className="st3" d="M403.39 299.73h17.78" />
      <path className="st3" d="M536.85 592.47h17.78" />
      <path className="st3" d="M588.66 579.86h17.78" />
      <path className="st3" d="M621.7 349.54h17.78" />
      <path className="st3" d="M724.41 267.68h17.79" />

      <path className="st3" d="M914.36 461.76h17.78" />

      <g id="g-lt03">
        <circle className={`st12 ${clsLt03}`} cx={1018.42} cy={233.45} r={11.35} />
        <path className="st3" d="M1007.08 233.45h22.69" />
      </g>
      <g id="g-bv01">
        <path className="st3" d="M211.39 136.44v7.2-7.2z" />
        <path id="bv01-l" className={`st12 ${clsBv01}`} d="M211.28 143.64l-13.23-6.66v13.33z" />
        <path id="bv01-r" className={`st12 ${clsBv01}`} d="M211.39 143.64l13.23 6.67v-13.33z" />
        <path className={`st12 ${clsBv01}`} d="M206.09 125.86h10.59v10.59h-10.59z" />
      </g>
      <g id="g-bv11">
        <path className={`st3 ${clsBv11}`} d="M229.08 318.45h7.2-7.2z" />
        <path className={`st3 ${clsBv11}`} d="M236.28 318.45l-6.67 13.23h13.33z" />
        <path className={`st3 ${clsBv11}`} d="M236.28 318.45l6.66-13.23h-13.33z" />
        <path className={`st3 ${clsBv11}`} d="M218.5 313.16h10.59v10.59H218.5z" />
      </g>
      <g id="g-bv02">
        <path className={`st3 ${clsBv02}`} d="M607.06 556.07h7.2-7.2z" />
        <path className={`st12 ${clsBv02}`} d="M614.26 556.07l-6.66 13.22h13.34z" />
        <path className={`st12 ${clsBv02}`} d="M614.26 556.07l6.68-13.24H607.6z" />
        <path className={`st12 ${clsBv02}`} d="M596.49 550.77h10.59v10.59h-10.59z" />
      </g>
      <g id="g-bv07">
        <path className={`st3 ${clsBv07}`} d="M607.09 433.44h7.2-7.2z" />
        <path className={`st12 ${clsBv07}`} d="M614.29 433.44l-6.66 13.23h13.33z" />
        <path className={`st12 ${clsBv07}`} d="M614.29 433.44l6.67-13.23h-13.33z" />
        <path className={`st12 ${clsBv07}`} d="M596.51 428.13h10.59v10.59h-10.59z" />
      </g>
      <g id="g-bv05">
        <path className={`st3 ${clsBv05}`} d="M676 260.36v7.2-7.2z" />
        <path className={`st12 ${clsBv05}`} d="M676 267.56l-13.23-6.66v13.33z" />
        <path className={`st12 ${clsBv05}`} d="M676 267.56l13.23 6.67V260.9z" />
        <path className={`st12 ${clsBv05}`} d="M670.7 249.78h10.59v10.59H670.7z" />
      </g>
      <g id="g-bv04">
        <path className={`st3 ${clsBv04}`} d="M657.77 361.25v7.2-7.2z" />
        <path className={`st12 ${clsBv04}`} d="M657.77 368.45l-13.23-6.66v13.34z" />
        <path className={`st12 ${clsBv04}`} d="M657.77 368.45l13.23 6.68v-13.34z" />
        <path className={`st12 ${clsBv04}`} d="M652.47 350.68h10.59v10.59h-10.59z" />
      </g>
      <g id="g-bv09">
        <path className={`st3 ${clsBv09}`} d="M607.12 646.77h7.2-7.2z" />
        <path className={`st12 ${clsBv09}`} d="M614.32 646.77L607.64 660h13.35z" />
        <path className={`st12 ${clsBv09}`} d="M614.32 646.77l6.67-13.23h-13.35z" />
        <path className={`st12 ${clsBv09}`} d="M596.55 641.47h10.59v10.59h-10.59z" />
      </g>
      <g id="g-dv01">
        <path id="dv01-b" className={`st12 ${clsDv01_1}`} d="M313.73 318.99l-6.66 13.23h13.33z" />
        <path id="dv01-t" className={`st12 ${clsDv01_2}`} d="M313.73 318.99l6.67-13.23h-13.33z" />
        <path id="dv01-c" className={`st12 ${clsDv01}`} d="M313.73 318.99l13.23 6.67v-13.33z" />
        <circle className={`st12 ${clsDv01}`} cx={313.73} cy={318.99} r={6.24} />
        <text transform="translate(292.815 320.85)" className="st10 st11">
          {'DV01'}
        </text>
        <text transform="translate(295.815 331.85)" className="st10 st21">
          Pos 1
        </text>
        <text transform="translate(295.815 308.85)" className="st10 st21">
          Pos 2
        </text>
        <text transform="translate(321.815 328.85)" className="st10 st21">
          Common
        </text>
      </g>
      <g id="g-hv01">
        <path className={`st12 ${clsHv01}`} d="M266.83 318.52l-6.66 13.23h13.33zM266.83 318.52l6.67-13.23h-13.33z" />
        <circle className={`st12 ${clsHv01}`} cx={266.83} cy={318.52} r={6.24} />
      </g>
      <g id="g-bv10">
        <path className={`st3 ${clsBv10}`} d="M357.99 355.76v7.2-7.2z" />
        <path className={`st12 ${clsBv10}`} d="M357.99 362.96l-13.22-6.67v13.33z" />
        <path className={`st12 ${clsBv10}`} d="M357.99 362.96l13.23 6.66v-13.33z" />
        <path className={`st12 ${clsBv10}`} d="M352.71 345.17h10.59v10.59h-10.59z" />
      </g>
      <g id="g-dv02">
        <path id="dv02-t" className={`st12 ${clsDv02_1}`} d="M482.37 318.99l6.68-13.23h-13.34z" />
        <path id="dv02-b" className={`st12 ${clsDv02_2}`} d="M482.37 318.99l-6.66 13.23h13.34z" />
        <path id="dv02-l" className={`st12 ${clsDv02}`} d="M482.37 318.99l-13.23-6.66v13.33z" />
        <circle className={`st12 ${clsDv02}`} cx={482.37} cy={318.99} r={6.24} />
        <text transform="translate(490.815 331.85)" className="st10 st21">
          Pos 2
        </text>
        <text transform="translate(490.815 308.85)" className="st10 st21">
          Pos 1
        </text>
        <text transform="translate(460.815 328.85)" className="st10 st21">
          Common
        </text>
      </g>

      <g id="g-dv03">
        <path id="dv03-t" className={`st12 ${clsDv03_1}`} d="M482.17 506.39l6.68-13.23h-13.34z" />
        <path id="dv03-b" className={`st12 ${clsDv03_2}`} d="M482.17 506.39l-6.66 13.23h13.34z" />
        <path id="dv03-l" className={`st12 ${clsDv03}`} d="M482.17 506.39l-13.23-6.66v13.34z" />
        <circle className={`st12 ${clsDv03}`} cx={482.17} cy={506.39} r={6.24} />
        <text transform="translate(490.43 508.58)" className="st10 st11">
          {'DV03'}
        </text>
        <text transform="translate(490.815 517.85)" className="st10 st21">
          Pos 2
        </text>
        <text transform="translate(490.815 497.85)" className="st10 st21">
          Pos 1
        </text>
        <text transform="translate(460.815 515.85)" className="st10 st21">
          Common
        </text>
      </g>
      <g id="g-bv03">
        <path className={`st3 ${clsBv03}`} d="M700.5 562.8h7.2-7.2z" />
        <path className={`st12 ${clsBv03}`} d="M707.68 562.8l-6.66 13.23h13.34z" />
        <path className={`st12 ${clsBv03}`} d="M707.68 562.8l6.68-13.23h-13.34z" />
        <path className={`st12 ${clsBv03}`} d="M689.91 557.51h10.59v10.59h-10.59z" />
      </g>
      <g id="g-bv06">
        <path className={`st3 ${clsBv06}`} d="M929.93 620.58v7.2-7.2z" />
        <path className={`st12 ${clsBv06}`} d="M929.93 627.77l-13.23-6.66v13.33z" />
        <path className={`st12 ${clsBv06}`} d="M929.93 627.77l13.23 6.67v-13.33z" />
        <path className={`st12 ${clsBv06}`} d="M924.63 609.99h10.59v10.59h-10.59z" />
      </g>
      <g id="g-bv08">
        <path className={`st3 ${clsBv08}`} d="M810.21 353.99h7.2-7.2z" />
        <path className={`st12 ${clsBv08}`} d="M817.41 353.99l-6.67 13.23h13.33z" />
        <path className={`st12 ${clsBv08}`} d="M817.41 353.99l6.66-13.23h-13.33z" />
        <path className={`st12 ${clsBv08}`} d="M799.63 348.69h10.59v10.59h-10.59z" />
      </g>

      <g id="g-hv02">
        <path className={`st12 ${clsHv02}`} d="M930.27 592.47l-13.23-6.66v13.33zM930.27 592.47l13.23 6.67v-13.33z" />
        <circle className={`st12 ${clsHv02}`} cx={930.27} cy={592.47} r={6.24} />
      </g>
      <g id="g-f04">
        <text transform="translate(408.245 548.765)" className="st10 st11">
          {'F04'}
        </text>
        <path className="st12" d="M417.69 529.54h35.28v35.28h-35.28z" />
        <path className="st9" d="M417.69 529.54l35.28 35.28" />
      </g>

      <g>
        <path className="st12" d="M869.54 243.35h18.35v122.93h-18.35z" />
        <ellipse className="st12" cx={878.71} cy={304.82} rx={6.56} ry={54.77} />
      </g>
      <g id="g-p01">
        <circle id="p01-c1" className={`st12 ${clsP01}`} cx={355.11} cy={318.98} r={21.16} />
        <path id="p01-p" className={`st12 ${clsP01}`} d="M355.11 340.155l21.16-21.16l-21.16-21.16" />
      </g>

      <g id="g-nrv07" transform="matrix(-0.65296835,0,0,0.5599017,616.34344,-33.033247)">
        <path className="st12" d="m 329.42,621.77 v 13.34 l 26.34,-13.34 v 13.34" id="path884-6-0" />
        <circle className="st12" cx="329.42001" cy="634.77002" r="1.38" id="circle886-2-0" />
      </g>
      <text transform="translate(385.2,312.11)" className="st10 st11">
        {'NRV07'}
      </text>
      <path className="st3" d="m 406.84082,331.03814 c 0.0311,-11.7533 0.0311,-11.94443 0.0311,-11.7533 0,0.19113 0,0.19113 0,0.19113" />

      <g id="g-nrv06" transform="matrix(0,0.65296835,-0.5599017,0,758.82339,116.058)">
        <path className="st3" d="m 329.42,621.77 v 13.34 l 26.34,-13.34 v 13.34" />
        <ellipse className="st12" cx="329.42001" cy="634.77002" rx="1.3795804" ry="1.38" />
      </g>
      <text transform="translate(386.076,342.549)" className="st10 st11">
        {'NRV06'}
      </text>
      <path className="st3" d="m 407.2545,357.9 c 0.0152,-9.18108 0.0152,-9.18108 0.0152,-9.18108" id="path35088" />
      <path className="st3" d="m 407.2545,357.43344 c 2.8,0 5.1,2.3 5.1,5.1 0,2.8 -2.3,5.1 -5.1,5.1" />

      <path className="st3" d="m 406.99731,415.21966 c 0.25719,-47.58622 0.25719,-47.58622 0.25719,-47.58622" />
      <g id="g-p03" transform="rotate(-90,446.74598,358.74367)">
        <circle id="p03-c1-9" className={`st3 ${clsP03}`} cx="369.10999" cy="318.98001" r="21.16" />
        <path id="p03-p-4" className={`st3 ${clsP03}`} d="m 369.11,340.155 21.16,-21.16 -21.16,-21.16" />
      </g>
      <text className="st10 st11" x="374.61429" y="435.75098">
        P03
      </text>
      <path className="st3" d="m 406.90912,457.37764 c -0.25377,172.61119 -0.25377,172.39564 -0.25377,170.0" />

      <text transform="translate(308.55 289.113)" className="st10 st11">
        {'TB01'}
      </text>
      <text transform="translate(1013.937 240.022)" className="st10 st11">
        {'LT03'}
      </text>

      {/* Sensor Tooltip */}

      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'tb01'}
        sensorValue={tb01}
        unitComponentParts={unitComponentParts}
        x={'325'}
        y={'200'}
      />
      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'tb02'}
        sensorValue={tb02}
        unitComponentParts={unitComponentParts}
        x={'354'}
        y={'513'}
      />

      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'tt01'}
        sensorValue={tt01}
        unitComponentParts={unitComponentParts}
        x={'359'}
        y={'621'}
      />

      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'tb03'}
        sensorValue={tb03}
        unitComponentParts={unitComponentParts}
        x={'733'}
        y={'267'}
      />

      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'lt01'}
        sensorValue={lt01_tooltip}
        unitComponentParts={unitComponentParts}
        x={'290'}
        y={'80'}
      />
      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'lt02'}
        sensorValue={lt02_tooltip}
        unitComponentParts={unitComponentParts}
        x={'920'}
        y={'480'}
      />

      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'ct01'}
        sensorValue={ct01}
        unitComponentParts={unitComponentParts}
        x={'547'}
        y={'603'}
      />

      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'cs01'}
        sensorValue={cs01}
        unitComponentParts={unitComponentParts}
        x={'33'}
        y={'509'}
      />

      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'pt01'}
        sensorValue={pt01}
        unitComponentParts={unitComponentParts}
        x={'300'}
        y={'350'}
      />
      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'pt02'}
        sensorValue={pt02}
        unitComponentParts={unitComponentParts}
        x={'526'}
        y={'495'}
      />
      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'pt03'}
        sensorValue={pt03}
        unitComponentParts={unitComponentParts}
        x={'630'}
        y={'349'}
      />

      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'ft01'}
        sensorValue={ft01}
        unitComponentParts={unitComponentParts}
        x={'480'}
        y={'260'}
      />
      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'ft02'}
        sensorValue={ft02}
        unitComponentParts={unitComponentParts}
        x={'910'}
        y={'350'}
      />
      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'uvi01'}
        sensorValue={uvi01}
        unitComponentParts={unitComponentParts}
        x={'910'}
        y={'250'}
      />
      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'uvd01'}
        sensorValue={uvd01}
        unitComponentParts={unitComponentParts}
        x={'910'}
        y={'160'}
      />
      <SensorToolTip
        showSensors={ShowSensors}
        sensorName={'tm01'}
        sensorValue={tm01}
        unitComponentParts={unitComponentParts}
        x={'33'}
        y={'644'}
      />
    </svg>
  );
};

export default DeviceSchematicSvg;
